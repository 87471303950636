/* eslint-disable import/prefer-default-export */
import translation from 'lux/components/translation';
import { Banner } from 'lux/pages/ApplicantResumes/components/redesign/Banner';

const PROMO_LINK = '/applicant/services/payment?from=myresumes_promo&hhtmFromLabel=myresumes_promo';

const TrlKeys = {
    title: 'FindJobPromo.title',
    order: 'FindJobPromo.activate',
    description: 'FindJobPromo.description',
};

export const FindJobPromo = translation(({ trls }) => {
    return (
        <Banner
            href={PROMO_LINK}
            title={trls[TrlKeys.title]}
            button={trls[TrlKeys.order]}
            description={trls[TrlKeys.description]}
        />
    );
});
