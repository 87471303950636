import { createContext, useContext } from 'react';

import { LangTrls } from 'bloko/common/hooks/useTranslations';

import { RecommendationDetails } from 'lux/models/applicantResumesStatistics';

type TBotUpdateResumeContext = {
    resumeId: number;
    resumeHash: string;
    chatBotHref: string;
    modalVisible: boolean;
    successUpdate: boolean;
    handleCloseModal: () => void;
    canUpdateResumeByBot: boolean;
    recommendationDetails?: RecommendationDetails;
};

export const BotUpdateResumeContext = createContext<TBotUpdateResumeContext>({
    resumeId: 0,
    resumeHash: '',
    chatBotHref: '',
    modalVisible: false,
    successUpdate: false,
    handleCloseModal: () => void 0,
    canUpdateResumeByBot: false,
});

const TrlKeys = {
    modalTitle: 'botUpdateResume.modal.title',
    modalText: 'botUpdateResume.modal.text',
    actionButton: 'botUpdateResume.modal.actionButton',
    promoButton: 'botUpdateResume.modal.promoButton',
    closeButton: 'botUpdateResume.modal.closeButton',
};

type BotUpdateResumeModalText = {
    title: string;
    content: string;
    promoButton: string;
    closeButton: string;
    actionButton: string;
};

export const getTexts = (trls: LangTrls): BotUpdateResumeModalText => {
    return {
        title: trls[TrlKeys.modalTitle],
        content: trls[TrlKeys.modalText],
        actionButton: trls[TrlKeys.actionButton],
        promoButton: trls[TrlKeys.promoButton],
        closeButton: trls[TrlKeys.closeButton],
    };
};

export const useBotUpdateResumeContext = (): TBotUpdateResumeContext => useContext(BotUpdateResumeContext);
