/* eslint-disable import/prefer-default-export */
import { FC } from 'react';

import { Card, Text, Button, VSpacing, useBreakpoint, CardProps } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import styles from './styles.less';

type Props = {
    href?: string;
    title: string;
    button: string;
    onClick?: () => void;
    description: string;
    style?: CardProps['style'];
    'data-qa'?: string;
};

export const Banner: FC<Props> = ({ title, description, button, href, onClick, style = 'neutral', ...rest }) => {
    const { isMobile } = useBreakpoint();

    return (
        <Card stretched borderRadius={24} style={style} {...rest}>
            <div className={styles.wrapper}>
                <div className={styles.content}>
                    <Text typography="title-5-semibold">{title}</Text>
                    <VSpacing default={6} />
                    <Text typography="paragraph-2-regular">{description}</Text>
                </div>
                {href ? (
                    <Button
                        to={href}
                        Element={Link}
                        size={isMobile ? 'small' : 'medium'}
                        mode="primary"
                        style="accent"
                        stretched={isMobile}
                        onClick={onClick}
                    >
                        {button}
                    </Button>
                ) : (
                    <Button
                        size={isMobile ? 'small' : 'medium'}
                        mode="primary"
                        style="accent"
                        stretched={isMobile}
                        onClick={onClick}
                    >
                        {button}
                    </Button>
                )}
            </div>
        </Card>
    );
};
