import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import resumeCreationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/creation/resume_creation_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { HoverTip } from 'bloko/blocks/drop';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';
import useBreakpoint, { Breakpoint } from 'bloko/common/hooks/useBreakpoint';
import { format } from 'bloko/common/trl';

import { sendAnalyticsFindJobFaster, sendAnalyticsCompleteResume } from 'Modules/ApplicantAnalytics';
import { ApplicantServices } from 'lux/components/ApplicantServices/common';
import ImportResumeModal from 'lux/components/ImportResumeModal';
import translation from 'lux/components/translation';
import { useIsHeadHunterPlatform, useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

const ApplicantNavigation = ({
    max,
    remaining,
    trls,
    hasRenewalService,
    hasCompleteResume,
    createResumeHighlighted,
}) => {
    const userType = useSelector(({ userType }) => userType);
    const createResumeButtonProps = !createResumeHighlighted ? {} : { kind: ButtonKind.Success };
    const isXs = useBreakpoint() === Breakpoint.XS;
    const isHH = useIsHeadHunterPlatform();
    const isZp = useIsZarplataPlatform();
    const fakeRef = useRef(null);

    const [importZpVisible, setImportZpVisible] = useState(false);

    const resumeCreateButtonHandler = () => {
        resumeCreationButtonClick({
            hhtmSourceLabel: 'resume_list',
        });
        Analytics.sendEvent('applicant', 'click-create-resume');
    };

    const handleZpClick = () => {
        setImportZpVisible(true);
    };

    const zpButton = (
        <Button onClick={handleZpClick} {...createResumeButtonProps} stretched>
            {trls[ApplicantNavigation.trls.createResume]}
        </Button>
    );

    const hhButton = (
        <Button
            onClick={resumeCreateButtonHandler}
            {...createResumeButtonProps}
            Element={Link}
            to="/applicant/resumes/new"
            stretched
            rel="nofollow"
        >
            {trls[ApplicantNavigation.trls.createResume]}
        </Button>
    );

    const button = isZp ? zpButton : hhButton;

    const btnCreateResume = (
        <div className="applicant-navigation-item applicant-navigation-item_adaptive">
            {remaining !== 0 ? (
                button
            ) : (
                <HoverTip
                    render={() => (
                        <span data-qa="max-resume-warning">
                            {format(trls[ApplicantNavigation.trls.maxResumes], { '{0}': max })}
                        </span>
                    )}
                >
                    <div>
                        <Button Element={'span'} kind={ButtonKind.Success} disabled stretched>
                            {trls[ApplicantNavigation.trls.createResume]}
                        </Button>
                    </div>
                </HoverTip>
            )}
        </div>
    );

    const btnFindJobFaster =
        hasRenewalService && isHH ? (
            <div className="applicant-navigation-item">
                <Button
                    Element={Link}
                    to="/applicant/services/payment?from=myresume&package=basic"
                    data-qa="resumeservice-button__renewresume"
                    onMouseUp={sendAnalyticsFindJobFaster}
                    stretched
                >
                    {trls[ApplicantNavigation.trls.findJobFaster]}
                </Button>
            </div>
        ) : null;

    const btnCompleteResumeMobile =
        hasCompleteResume && isHH ? (
            <div className="applicant-navigation-item applicant-navigation-item_complete-resume">
                <BlokoLink
                    Element={Link}
                    kind={LinkKind.Tertiary}
                    to="/article/expert_resume?from=myresume"
                    onClick={() => sendAnalyticsCompleteResume(userType)}
                >
                    {trls[ApplicantNavigation.trls.completeResume]}
                </BlokoLink>
            </div>
        ) : null;

    const btnCompleteResumeDesktop =
        hasCompleteResume && isHH ? (
            <div className="applicant-navigation-item applicant-navigation-item_adaptive">
                <Button
                    Element={Link}
                    to="/article/expert_resume?from=myresume"
                    onClick={() => sendAnalyticsCompleteResume(userType)}
                    stretched
                >
                    {trls[ApplicantNavigation.trls.completeResume]}
                </Button>
            </div>
        ) : null;

    return (
        <>
            {isXs ? (
                <div className="applicant-navigation applicant-navigation-wrapper">
                    {btnCreateResume}
                    {btnFindJobFaster}
                    {btnCompleteResumeMobile}
                </div>
            ) : (
                <div className="applicant-navigation applicant-navigation-wrapper">
                    {btnCompleteResumeDesktop}
                    {btnCreateResume}
                </div>
            )}
            <ImportResumeModal
                activatorRef={fakeRef}
                visible={importZpVisible}
                onClose={() => setImportZpVisible(false)}
                url={'/applicant/resumes/new'}
            />
        </>
    );
};

ApplicantNavigation.propTypes = {
    remaining: PropTypes.number,
    max: PropTypes.number,
    handlerOnClose: PropTypes.func,
    hasRenewalService: PropTypes.bool,
    hasCompleteResume: PropTypes.bool,
    createResumeHighlighted: PropTypes.bool,
    trls: PropTypes.object,
};

ApplicantNavigation.trls = {
    createResume: 'resumeList.newResumeCreate',
    findJobFaster: 'resumeList.tools.findJobFaster',
    completeResume: 'resumeList.tools.complete_resume',
    infoTipLink: 'notifications.renewResume.findOut',
    maxResumes: 'resumeList.maxResumes',
};

export default connect((state) => ({
    max: state.resumeLimits.max,
    remaining: state.resumeLimits.remaining,
    hasRenewalService: state.applicantPaymentServices.includes(ApplicantServices.ResumeRenewal),
    hasCompleteResume: state.applicantPaymentServices.includes(ApplicantServices.CompleteResume),
}))(translation(ApplicantNavigation));
