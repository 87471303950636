import { useContext } from 'react';

import Analytics from '@hh.ru/analytics-js';
import resumeUpdateSuccessButtonClick, {
    ButtonName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_update_success_button_click';
import Button, { ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import Modal, { ModalHeader, ModalTitle, ModalContent } from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import translation from 'lux/components/translation';

import BotUpdateResumeContext, { getTexts } from 'lux/pages/ApplicantResumes/components/BotUpdateResumeContext';

import styles from './resume.less';

const DataQa = {
    root: 'bot-update-resume-modal',
    actionButton: 'bot-update-resume-modal__action-button',
    promoButton: 'bot-update-resume-modal__promo-button',
    closeButton: 'bot-update-resume-modal__close-button',
};

const BotUpdateResumeModal: TranslatedComponent = ({ trls }) => {
    const { modalVisible, handleCloseModal, resumeHash, resumeId, chatBotHref } = useContext(BotUpdateResumeContext);

    const { title, content, actionButton, promoButton, closeButton } = getTexts(trls);

    const promoHref = `/applicant/services/findjob?${urlParser.stringify({
        hhtmFrom: 'resume_update_success',
        resumeHash,
    })}`;

    const sendAnalytics = (label: string, buttonName: ButtonName) => {
        Analytics.sendEvent('applicant', 'resume_renew_modal', label);
        resumeUpdateSuccessButtonClick({ resumeId, buttonName });
    };

    return (
        <Modal visible={modalVisible} onClose={handleCloseModal} data-qa={DataQa.root}>
            <ModalHeader>
                <ModalTitle>{title}</ModalTitle>
            </ModalHeader>

            <ModalContent>
                <Text size={TextSize.Medium} style={{ whiteSpace: 'pre-line', minWidth: '275px' }}>
                    {content}
                </Text>

                <VSpacing base={5} />

                <Button
                    stretched
                    Element="a"
                    href={chatBotHref}
                    appearance={ButtonAppearance.Outlined}
                    onClick={() => {
                        sendAnalytics('bot_promote', 'update_resume_by_bot');
                    }}
                    data-qa={DataQa.actionButton}
                >
                    {actionButton}
                </Button>

                <VSpacing base={6} />

                <div className={styles.divider} />

                <VSpacing base={6} />

                <Button
                    stretched
                    Element="a"
                    kind={ButtonKind.Success}
                    href={promoHref}
                    onClick={() => {
                        sendAnalytics('promote_resume', 'resume_renewal');
                    }}
                    data-qa={DataQa.promoButton}
                >
                    {promoButton}
                </Button>

                <VSpacing base={4} />

                <Button
                    stretched
                    onClick={handleCloseModal}
                    appearance={ButtonAppearance.Outlined}
                    data-qa={DataQa.closeButton}
                >
                    {closeButton}
                </Button>
            </ModalContent>
        </Modal>
    );
};

export default translation(BotUpdateResumeModal);
