import { ComponentType } from 'react';
import classNames from 'classnames';

import Analytics from '@hh.ru/analytics-js';
import {
    Button,
    Card,
    HSpacing,
    Link,
    ProgressBar,
    Text,
    useBreakpoint,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';

import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const TrlKeys = {
    part: {
        title: 'applicant.resumes.vacancy.responses.counter.part.title',
        hint: 'applicant.resumes.vacancy.responses.counter.part.hint',
    },
    full: {
        title: 'applicant.resumes.vacancy.responses.counter.full.title',
        hint: 'applicant.resumes.vacancy.responses.counter.full.hint',
    },
    button: 'applicant.resumes.vacancy.responses.counter.button',
};

interface Props {
    relatedVacanciesHash: string;
}

const VacancyResponseCounter: ComponentType<Props> = translation(({ relatedVacanciesHash, trls }) => {
    const { isMobile } = useBreakpoint();
    const responsesRequired = useSelector(
        ({ applicantResumesStatistics }) => applicantResumesStatistics.recommendationsForAllResumes?.responsesRequired
    );
    const responsesCount = useSelector(
        ({ applicantResumesStatistics }) => applicantResumesStatistics.recommendationsForAllResumes?.responsesCount ?? 0
    );

    if (!responsesRequired) {
        return null;
    }

    const icon = (
        <div
            className={classNames({
                [styles.iconEmpty]: responsesCount === 0,
                [styles.iconPart]: responsesCount < responsesRequired && responsesCount > 0,
                [styles.iconFull]: responsesCount >= responsesRequired,
            })}
        />
    );

    const delta = responsesRequired - responsesCount;
    const href = `${paths.vacancySearch}?resume=${relatedVacanciesHash}&from=resumelist`;
    const sendClickAnalytics = () => Analytics.sendHHEventButtonClick('vacancy-response-counter-suitable');
    return (
        <Card stretched padding={isMobile ? 12 : 24} showBorder borderRadius={24}>
            <div className={styles.container}>
                {!isMobile ? (
                    <>
                        {icon}
                        <HSpacing default={12} />
                    </>
                ) : undefined}
                <div className={styles.mainContent}>
                    <VSpacingContainer default={4}>
                        <Text typography="subtitle-1-semibold">
                            {delta > 0
                                ? trls[TrlKeys.part.title].replace('{0}', String(delta))
                                : trls[TrlKeys.full.title].replace('{0}', String(responsesCount))}
                        </Text>
                        <Text typography="label-4-regular" style="secondary">
                            {delta > 0
                                ? trls[TrlKeys.part.hint].replace('{0}', String(responsesRequired))
                                : trls[TrlKeys.full.hint]}
                        </Text>
                    </VSpacingContainer>
                </div>
                <HSpacing default={12} />
                {isMobile ? (
                    <>{icon}</>
                ) : (
                    <div className={styles.linkContainer}>
                        <Link Element={SpaLink} typography="label-3-regular" onClick={sendClickAnalytics} to={href}>
                            {trls[TrlKeys.button]}
                        </Link>
                    </div>
                )}
            </div>
            <VSpacing default={16} />
            <ProgressBar progress={Math.min((responsesCount / responsesRequired) * 100, 100)} />
            {isMobile && (
                <>
                    <VSpacing default={24} />
                    <Button
                        Element={SpaLink}
                        to={href}
                        mode="secondary"
                        size="small"
                        stretched
                        onClick={() => {
                            sendClickAnalytics();
                        }}
                    >
                        {trls[TrlKeys.button]}
                    </Button>
                </>
            )}
        </Card>
    );
});

export default VacancyResponseCounter;
