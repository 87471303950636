import { useMemo } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import careerBannerClick, {
    type HhtmSource,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-28772/career_banner_click';
import careerBannerShown from '@hh.ru/analytics-js-events/build/xhh/applicant/experiments/PORTFOLIO-28772/career_banner_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import { H3Section } from 'bloko/blocks/header';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { CareerBannerPosition, CareerLinksKeys, CareerPlatformLinks } from 'lux/models/careerPlatformState';
import { useSelector } from 'lux/modules/useSelector';

import translation from '../translation';

import styles from './index.less';

interface Props {
    position: CareerBannerPosition;
    destination?: CareerLinksKeys;
}

const TrlKeys = {
    titleRegular: 'careerPlatformBanner.regular.title',
    titleRole: 'careerPlatformBanner.role.title',
    titleResume: 'careerPlatformBanner.resume.title',
    descriptionRegular: 'careerPlatformBanner.regular.description',
    descriptionRole: 'careerPlatformBanner.role.description',
    descriptionResume: 'careerPlatformBanner.resume.description',
    linkRegular: 'careerPlatformBanner.regular.link',
    linkRole: 'careerPlatformBanner.role.link',
};

const getStrings = (trls: LangTrls, position: CareerBannerPosition, role: string, searchByRole: boolean) => {
    switch (position) {
        case CareerBannerPosition.Profile:
            return {
                title: format(trls[TrlKeys.titleResume], { '{role}': role }),
                description: trls[TrlKeys.descriptionResume],
                link: trls[TrlKeys.linkRole],
            };
        case CareerBannerPosition.Search:
            return {
                title:
                    searchByRole && role
                        ? format(trls[TrlKeys.titleRole], { '{role}': role })
                        : trls[TrlKeys.titleRegular],
                description: searchByRole && role ? trls[TrlKeys.descriptionResume] : trls[TrlKeys.descriptionRegular],
                link: searchByRole && role ? trls[TrlKeys.linkRole] : trls[TrlKeys.linkRegular],
            };
        case CareerBannerPosition.Resume:
        case CareerBannerPosition.Negotiations:
        default:
            return {
                title: trls[TrlKeys.titleRegular],
                description: trls[TrlKeys.descriptionRegular],
                link: trls[TrlKeys.linkRegular],
            };
    }
};

const getLink = (byRole: boolean, links: CareerPlatformLinks, destination?: CareerLinksKeys) => {
    if (byRole) {
        return links[CareerLinksKeys.Calc];
    }

    if (destination && destination !== CareerLinksKeys.Catalog) {
        return links[destination];
    }

    return CareerLinksKeys.Catalog;
};

const CareerPlatformBanner: TranslatedComponent<Props> = ({ trls, destination, position }) => {
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource) as HhtmSource;
    const linksState = useSelector((state) => state.careerPlatformLinks);
    const links = linksState && linksState.links;
    const isRole = !!(linksState && linksState.is_role);

    const analyticParams = {
        resumeHash: linksState?.resume_hash,
        [isRole ? 'searchProfession' : 'resumeProfession']: links?.profession_name,
        hhtmSource,
    };
    const handleClick = () => {
        careerBannerClick(analyticParams);
    };

    const strings = useMemo(() => {
        if (links) {
            return getStrings(trls, position, links.profession_name, isRole);
        }
        return null;
    }, [isRole, links, position, trls]);

    if (!links || !strings) {
        return null;
    }

    return (
        <ElementShownAnchor fn={careerBannerShown} {...analyticParams}>
            <div className={styles.links}>
                <div>
                    <H3Section>{strings.title}</H3Section>
                    <VSpacing base={2} />
                    <Text>{strings.description}</Text>
                </div>
                <Button
                    kind={ButtonKind.Success}
                    appearance={ButtonAppearance.Filled}
                    to={getLink(isRole, links, destination)}
                    target={'_blank'}
                    Element={Link}
                    onClick={handleClick}
                >
                    {strings.link}
                </Button>
            </div>
        </ElementShownAnchor>
    );
};

export default translation(CareerPlatformBanner);
