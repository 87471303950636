/* eslint-disable import/prefer-default-export */
import Analytics from '@hh.ru/analytics-js';
import resumeUpdateSuccessButtonClick, {
    ButtonName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_update_success_button_click';
import {
    Modal,
    Action,
    Text,
    Button,
    BottomSheet,
    useBreakpoint,
    BottomSheetFooter,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import translation from 'lux/components/translation';
import {
    useBotUpdateResumeContext,
    getTexts,
} from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/botUpdateResume';

import styles from './styles.less';

const DataQa = {
    root: 'bot-update-resume-modal',
    actionButton: 'bot-update-resume-modal__action-button',
    promoButton: 'bot-update-resume-modal__promo-button',
    closeButton: 'bot-update-resume-modal__close-button',
};

export const BotUpdateModal = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const { modalVisible, handleCloseModal, resumeHash, resumeId, chatBotHref } = useBotUpdateResumeContext();

    const { title, content, actionButton, promoButton } = getTexts(trls);

    const promoHref = `/applicant/services/findjob?${urlParser.stringify({
        hhtmFrom: 'resume_update_success',
        resumeHash,
    })}`;

    const sendAnalytics = (label: string, buttonName: ButtonName) => {
        Analytics.sendEvent('applicant', 'resume_renew_modal', label);
        resumeUpdateSuccessButtonClick({ resumeId, buttonName });
    };
    if (isMobile) {
        return (
            <BottomSheet
                visible={modalVisible}
                onClose={handleCloseModal}
                data-qa={DataQa.root}
                footer={
                    <BottomSheetFooter>
                        <Button
                            stretched
                            Element={Link}
                            mode="primary"
                            style="accent"
                            to={promoHref}
                            onClick={() => {
                                sendAnalytics('promote_resume', 'resume_renewal');
                            }}
                            data-qa={DataQa.promoButton}
                        >
                            {promoButton}
                        </Button>

                        <Button
                            stretched
                            Element={Link}
                            to={chatBotHref || ''}
                            mode="secondary"
                            style="accent"
                            onClick={() => {
                                sendAnalytics('bot_promote', 'update_resume_by_bot');
                            }}
                            data-qa={DataQa.actionButton}
                        >
                            {actionButton}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                <div className={styles.navbar}>
                    <Action style="neutral" mode="secondary" icon={CrossOutlinedSize24} onClick={handleCloseModal} />
                </div>
                <Text typography="title-4-semibold">{title}</Text>
                <VSpacing default={4} />
                <Text typography="label-2-regular" style="secondary">
                    {content}
                </Text>
                <VSpacing default={32} />
            </BottomSheet>
        );
    }
    return (
        <Modal
            title={title}
            visible={modalVisible}
            onClose={handleCloseModal}
            data-qa={DataQa.root}
            actions={<Action style="neutral" mode="secondary" icon={CrossOutlinedSize24} onClick={handleCloseModal} />}
            rightButtons={
                <>
                    <Button
                        Element={Link}
                        to={chatBotHref || ''}
                        mode="secondary"
                        style="accent"
                        onClick={() => {
                            sendAnalytics('bot_promote', 'update_resume_by_bot');
                        }}
                        data-qa={DataQa.actionButton}
                    >
                        {actionButton}
                    </Button>

                    <Button
                        Element={Link}
                        mode="primary"
                        style="accent"
                        to={promoHref}
                        onClick={() => {
                            sendAnalytics('promote_resume', 'resume_renewal');
                        }}
                        data-qa={DataQa.promoButton}
                    >
                        {promoButton}
                    </Button>
                </>
            }
        >
            <Text typography="paragraph-1-regular">{content}</Text>
        </Modal>
    );
});
