import React, { ComponentType } from 'react';

import { JobSearchStatus, JobSearchStatusSetted, JobSearchStatusForm } from 'lux/models/applicantUserStatuses';
import useSetJobSearchStatus from 'lux/pages/ApplicantResumes/components/redesign/JobSearchStatus/api/useSetJobSearchStatus';

import JobSearchStatusOption from 'lux/pages/ApplicantResumes/components/redesign/JobSearchStatus/components/JobSearchStatusOption';

import styles from './styles.less';

type Props = {
    id: JobSearchStatusForm;
    statusesList?: JobSearchStatusSetted[];
    status: JobSearchStatus;
    setStatus: (newStatus: JobSearchStatus) => void;
    resetStatus: () => void;
};

const STATUSES = [
    JobSearchStatus.ActiveSearch,
    JobSearchStatus.LookingForOffers,
    JobSearchStatus.HasJobOffer,
    JobSearchStatus.AcceptedJobOffer,
    JobSearchStatus.NotLookingForJob,
] as const;

const JobSearchStatusOptions: ComponentType<Props> = ({ id, statusesList, status, setStatus, resetStatus }) => {
    const setJobSearchStatus = useSetJobSearchStatus({ formId: id, resetJobSearchStatus: resetStatus });

    const onStatusChange = (newStatus: JobSearchStatus) => {
        setJobSearchStatus(newStatus);
        setStatus(newStatus);
    };

    const statusesToChoose = statusesList || STATUSES;

    return (
        <div className={styles.optionsContainer} data-qa="job-search-status-form">
            {statusesToChoose.map((value) => (
                <JobSearchStatusOption key={value} value={value} checkedValue={status} onValueUpdate={onStatusChange} />
            ))}
        </div>
    );
};

export default JobSearchStatusOptions;
