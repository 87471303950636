import PropTypes from 'prop-types';

const Wrapper = ({ children }) => (
    <div className="HHC-Banner-Wrapper clickme" data-loaded-class="clickme_loaded">
        {children}
    </div>
);

Wrapper.propTypes = {
    children: PropTypes.node,
};

export default Wrapper;
