/* eslint-disable import/prefer-default-export */
import { PropsWithChildren, useState, FC } from 'react';

import Analytics from '@hh.ru/analytics-js';

import sendHHAnalytics from 'HH/SendHHAnalytics';
import { useSelector } from 'lux/modules/useSelector';
import { deleteResumeApi } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/api/deleteResume';
import { hideResumeApi } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/api/hideResume';
import {
    Payload,
    ResumeDeleteModalContext,
} from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/resumeDeleteModal';

export const DeleteModalProvider: FC<PropsWithChildren> = ({ children }) => {
    const backUrl = useSelector(({ request }) => request.url);
    const hhtmSource = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);

    const [state, setState] = useState({ hash: '', from: '', visible: false });

    const openModal = ({ hash, from }: Payload) => {
        Analytics.sendEvent('applicant', 'resume-delete-attempt', 'try_to_delete');
        void sendHHAnalytics({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            toStorage: 'hh',
            data: {
                from: state.from,
                goal: 'resume-delete',
            },
        });
        setState({ hash, from, visible: true });
    };

    const closeModal = () => {
        Analytics.sendEvent('applicant', 'resume-delete-attempt', 'cancel');
        setState({ hash: '', from: '', visible: false });
    };

    const deleteResume = () => {
        deleteResumeApi(state.hash, 'resume-delete', hhtmSource)
            .then(({ data }) => {
                Analytics.sendEvent('applicant', 'resume-delete-complete-beacon', 'complete_delete', '', true);
                window.location.assign(data.url);
            })
            .catch(console.error);
    };

    const hideResume = () =>
        hideResumeApi(state.hash)
            .then(() => {
                window.location.assign(backUrl);
            })
            .catch(console.error);

    return (
        <ResumeDeleteModalContext.Provider
            value={{ visible: state.visible, closeModal, openModal, deleteResume, hideResume }}
        >
            {children}
        </ResumeDeleteModalContext.Provider>
    );
};
