import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import verificationKeySkillDescriptionScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_description_screen_shown';
import verificationKeySkillStartButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/verification_key_skill/verification_key_skill_start_button_click';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import Column from 'bloko/blocks/column';
import { FormSpacer } from 'bloko/blocks/form';
import HSpacing from 'bloko/blocks/hSpacing';
import { H1 } from 'bloko/blocks/header';
import Modal, { ModalContent, ModalFooter } from 'bloko/blocks/modal';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import capitalize from 'bloko/common/capitalize';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ApplicantSkillsVerificationPeriod from 'lux/components/ApplicantSkillsVerificationPeriod';
import ApplicantSkillsVerificationSource from 'lux/components/ApplicantSkillsVerificationSource';
import OutdatedSkill from 'lux/components/OutdatedSkill';
import TestRank from 'lux/components/TestCardRank/TestRank';
import translation from 'lux/components/translation';
import {
    ApplicantSkillsVerificationMethod,
    ApplicantSkillsVerificationMethodKeys,
} from 'lux/models/applicantSkillsVerificationMethods';
import fetcher from 'lux/modules/fetcher';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const QUIZ_TOKEN = '/shards/skills_profile/quiz_token';

interface QuizTokenResponse {
    hhid: number;
    quizId: string;
    token: string;
}

declare global {
    interface FetcherPostApi {
        [QUIZ_TOKEN]: {
            queryParams: { quizId: string };
            body: null;
            response: QuizTokenResponse;
        };
    }
}

const TrlKeys = {
    catalog: 'applicantSkillsVerificationMethods.catalog',
    resumes: 'applicantSkillsVerificationMethods.resumes',
    advertising: 'applicantSkillsVerificationMethods.advertising',
    tip: 'applicantSkillsVerificationMethods.tip',
    startTest: 'applicantSkillsVerificationMethods.startTest',
    content: 'applicantSkillsVerificationMethods.content',
    close: 'applicantSkillsVerificationMethods.modal.close',
};

interface MethodModalProps {
    visible: boolean;
    onClose: () => void;
    method?: ApplicantSkillsVerificationMethod;
}

const ApplicantSkillsVerificationMethodModal: TranslatedComponent<MethodModalProps> = ({
    visible,
    onClose,
    method,
    trls,
}) => {
    const requestOrigin = useSelector(({ request }) => request.origin);
    const dispatch = useDispatch();
    const isSkill = method?.verification_objects?.[0]?.category === 'SKILL';
    const rank = method?.verification_objects?.[0]?.level?.rank;
    useEffect(() => {
        if (method !== undefined) {
            verificationKeySkillDescriptionScreenShown({
                verificationTestName: method.name,
                isBrand: method[ApplicantSkillsVerificationMethodKeys.BrandingSettings].enabled,
                isPaidClick:
                    method[ApplicantSkillsVerificationMethodKeys.BrandingSettings][
                        ApplicantSkillsVerificationMethodKeys.CtaButtonEnabled
                    ],
                nameBrand: method.source.name,
                verificationMethodId: method.id,
                verificationSourceId: method.source.id,
                levelId: rank || 2,
            });
        }
    }, [method, rank]);

    if (method === undefined) {
        return null;
    }
    const expirationDate = method?.validity[ApplicantSkillsVerificationMethodKeys.ValidUntil]
        ? [method.validity[ApplicantSkillsVerificationMethodKeys.ValidUntil]]
        : null;
    const isBranding = method[ApplicantSkillsVerificationMethodKeys.BrandingSettings].enabled;
    const methodHref = method?.source.href ?? '';

    const quizId =
        method[ApplicantSkillsVerificationMethodKeys.KakDelaQuiz][ApplicantSkillsVerificationMethodKeys.QuizId];

    const handleStartTest = async (): Promise<void> => {
        if (method === undefined) {
            return;
        }
        const result = await fetcher.post(QUIZ_TOKEN, null, {
            params: { quizId },
        });
        const data = result.data;
        const urlTemplate =
            method[ApplicantSkillsVerificationMethodKeys.KakDelaQuiz][
                ApplicantSkillsVerificationMethodKeys.UrlTemplate
            ];
        const replacedUrl = urlTemplate
            .replace(/{{quiz_id}}/g, quizId)
            .replace(/{{token}}/, data.token)
            .replace(/{{redirect_url}}/, `${requestOrigin}/applicant/resumes?token=${data.token}`);
        verificationKeySkillStartButtonClick({
            verificationTestName: method.name,
            isBrand: method[ApplicantSkillsVerificationMethodKeys.BrandingSettings].enabled,
            isPaidClick:
                method[ApplicantSkillsVerificationMethodKeys.BrandingSettings][
                    ApplicantSkillsVerificationMethodKeys.CtaButtonEnabled
                ],
            nameBrand: method.source.name,
            verificationMethodId: method.id,
            verificationSourceId: method.source.id,
            levelId: rank || 2,
        });
        dispatch(push(replacedUrl));
    };

    return (
        <Modal onClose={onClose} visible={visible}>
            {method && (
                <>
                    <ModalContent>
                        <Column container xs="4" s="8" m="8" l="8">
                            <div className="resume-key-skills-verification-flex">
                                <div className="column">
                                    <H1>{method.name}</H1>
                                    {isBranding && (
                                        <div className="skills-verification-methods-header-grey">
                                            <Text
                                                importance={TextImportance.Tertiary}
                                                size={TextSize.Small}
                                            >{`${capitalize(methodHref)} ${trls[TrlKeys.advertising]} - ${
                                                method[ApplicantSkillsVerificationMethodKeys.BrandingSettings][
                                                    ApplicantSkillsVerificationMethodKeys.AgeLabel
                                                ]
                                            }`}</Text>
                                        </div>
                                    )}
                                </div>
                                {isBranding && method?.icon.defaults.original && (
                                    <>
                                        <HSpacing base={4} />
                                        <div className="resume-key-skills-verification-row-big-icon">
                                            <img loading="lazy" src={method?.icon.defaults.original} alt={methodHref} />
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={styles.tips}>
                                {expirationDate && (
                                    <>
                                        <VSpacing base={5} />
                                        <OutdatedSkill expirationDates={expirationDate} />
                                    </>
                                )}
                                <VSpacing base={5} />
                                <div className="skills-verification-methods-tip">
                                    <span>{trls[TrlKeys.tip]}</span>
                                </div>
                            </div>
                            <VSpacing base={7} />
                            <div className="resume-key-skills-verification-row">
                                {isSkill && (
                                    <div className="resume-key-skills-verification-row-item">
                                        <TestRank rank={rank} icon black />
                                    </div>
                                )}
                                {isBranding && (
                                    <ApplicantSkillsVerificationSource
                                        iconSource={method.icon.defaults.original}
                                        alt={method.source.href}
                                        name={method.source.name}
                                        className="resume-key-skills-verification-row-item"
                                        black
                                    />
                                )}
                                <ApplicantSkillsVerificationPeriod
                                    seconds={
                                        method[ApplicantSkillsVerificationMethodKeys.KakDelaQuiz][
                                            ApplicantSkillsVerificationMethodKeys.EstimatedTime
                                        ]
                                    }
                                    className="resume-key-skills-verification-row-item"
                                    black
                                />
                            </div>
                            <VSpacing base={7} />
                            <div className={'skills-verification-methods-content-experiment'}>
                                {trls[TrlKeys.content]}
                            </div>
                            <VSpacing base={2} />
                            <div className="skills-verification-methods-content">
                                <Text size={TextSize.Large}>
                                    {method[ApplicantSkillsVerificationMethodKeys.KakDelaQuiz].content}
                                </Text>
                            </div>
                            <VSpacing base={10} />
                        </Column>
                    </ModalContent>
                    <ModalFooter>
                        <FormSpacer>
                            <Button type={ButtonType.Button} onClick={onClose}>
                                {trls[TrlKeys.close]}
                            </Button>
                        </FormSpacer>
                        <FormSpacer>
                            <Button kind={ButtonKind.Primary} type={ButtonType.Button} onClick={handleStartTest}>
                                {trls[TrlKeys.startTest]}
                            </Button>
                        </FormSpacer>
                    </ModalFooter>
                </>
            )}
        </Modal>
    );
};

export default translation(ApplicantSkillsVerificationMethodModal);
