/* eslint-disable import/prefer-default-export */
import {
    Modal,
    Action,
    Text,
    Button,
    BottomSheet,
    useBreakpoint,
    BottomSheetFooter,
    VSpacing,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'lux/components/translation';
import { useResumeDeleteModalContext } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/resumeDeleteModal';

import styles from './styles.less';

const TrlKeys = {
    modalHeader: 'resumeList.delete.modal.header',
    modalDescription: 'resumeList.delete.modal.description',
    hideButton: 'resumeList.delete.modal.button.hide',
    deleteButton: 'resumeList.delete.modal.button.delete',
};

export const ResumeDeleteModal = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const { visible, closeModal, hideResume, deleteResume } = useResumeDeleteModalContext();

    if (isMobile) {
        return (
            <BottomSheet
                visible={visible}
                onClose={closeModal}
                footer={
                    <BottomSheetFooter>
                        <Button
                            stretched
                            mode="primary"
                            style="accent"
                            onClick={hideResume}
                            data-qa="resume-delete-confirm"
                        >
                            {trls[TrlKeys.hideButton]}
                        </Button>

                        <Button
                            stretched
                            mode="secondary"
                            style="negative"
                            data-qa="resume-delete-confirm"
                            onClick={deleteResume}
                        >
                            {trls[TrlKeys.deleteButton]}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                <div className={styles.navbar}>
                    <Action style="neutral" mode="secondary" icon={CrossOutlinedSize24} onClick={closeModal} />
                </div>
                <Text typography="title-4-semibold">{trls[TrlKeys.modalHeader]}</Text>
                <VSpacing default={4} />
                <Text typography="label-2-regular" style="secondary">
                    {trls[TrlKeys.modalDescription]}
                </Text>
                <VSpacing default={32} />
            </BottomSheet>
        );
    }

    return (
        <Modal
            title={trls[TrlKeys.modalHeader]}
            visible={visible}
            onClose={closeModal}
            actions={<Action style="neutral" mode="secondary" icon={CrossOutlinedSize24} onClick={closeModal} />}
            leftButtons={
                <Button mode="secondary" style="negative" data-qa="resume-delete-confirm" onClick={deleteResume}>
                    {trls[TrlKeys.deleteButton]}
                </Button>
            }
            rightButtons={
                <Button mode="primary" style="accent" onClick={hideResume} data-qa="resume-delete-confirm">
                    {trls[TrlKeys.hideButton]}
                </Button>
            }
        >
            <Text typography="paragraph-1-regular">{trls[TrlKeys.modalDescription]}</Text>
        </Modal>
    );
});
