import { ComponentType, useRef, useState } from 'react';

import Analytics from '@hh.ru/analytics-js';
import resumeCreationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/creation/resume_creation_button_click';
import { Alert, Button, Tooltip, useBreakpoint } from '@hh.ru/magritte-ui';
import { InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { Link as SpaLink, usePush } from '@hh.ru/redux-spa-middleware';
import { format } from 'bloko/common/trl';

import { sendAnalyticsFindJobFaster, sendAnalyticsCompleteResume } from 'Modules/ApplicantAnalytics';
import { ApplicantServices } from 'lux/components/ApplicantServices/common';
import ImportResumeModal from 'lux/components/ImportResumeModal';
import translation from 'lux/components/translation';
import { useIsHeadHunterPlatform, useIsZarplataPlatform } from 'lux/hooks/usePlatform';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const TrlKeys = {
    createResume: 'resumeList.newResumeCreate',
    findJobFaster: 'resumeList.tools.findJobFaster',
    completeResume: 'resumeList.tools.complete_resume',
    infoTipLink: 'notifications.renewResume.findOut',
    maxResumesTitle: 'resumeList.maxResumes.title',
    maxResumesHint: 'resumeList.maxResumes.hint',
    maxResumesButton: 'resumeList.maxResumes.button',
    maxResumesCloseAreaLabel: 'resumeList.maxResumes.close-area-label',
};

const ApplicantNavigation: ComponentType = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const push = usePush();
    const [maxResumeHintVisible, setMaxResumeHintVisible] = useState(false);
    const createButtonRef = useRef<HTMLButtonElement>(null);
    const userType = useSelector(({ userType }) => userType);
    const isHH = useIsHeadHunterPlatform();
    const isZp = useIsZarplataPlatform();
    const maxResume = useSelector((state) => state.resumeLimits?.max ?? 0);
    const remainingResume = useSelector((state) => state.resumeLimits?.remaining ?? 0);
    const hasRenewalService = useSelector((state) =>
        state.applicantPaymentServices.includes(ApplicantServices.ResumeRenewal)
    );
    const hasCompleteResume = useSelector((state) =>
        state.applicantPaymentServices.includes(ApplicantServices.CompleteResume)
    );
    const [importZpVisible, setImportZpVisible] = useState(false);

    const resumeCreateButtonHandler = () => {
        if (remainingResume === 0) {
            setMaxResumeHintVisible(true);
        } else {
            resumeCreationButtonClick({
                hhtmSourceLabel: 'resume_list',
            });
            Analytics.sendEvent('applicant', 'click-create-resume');
            push('/applicant/resumes/new');
        }
    };

    const handleZpClick = () => {
        if (remainingResume === 0) {
            setMaxResumeHintVisible(true);
        } else {
            setImportZpVisible(true);
        }
    };

    const closeHint = () => setMaxResumeHintVisible(false);

    const createResumeButton = isZp ? (
        <Button
            data-qa="resumeservice-button__createResumeZP"
            ref={createButtonRef}
            onClick={handleZpClick}
            stretched={isMobile}
            style="neutral"
            mode="secondary"
            size="small"
        >
            {trls[TrlKeys.createResume]}
        </Button>
    ) : (
        <Button
            data-qa="resumeservice-button__createResumeHH"
            ref={createButtonRef}
            onClick={resumeCreateButtonHandler}
            style="neutral"
            mode="secondary"
            size="small"
            stretched={isMobile}
        >
            {trls[TrlKeys.createResume]}
        </Button>
    );

    const maxResumesHint = (
        <>
            {isMobile ? (
                <>
                    <Alert
                        layout="horizontal"
                        visible={maxResumeHintVisible}
                        title={format(trls[TrlKeys.maxResumesTitle], { '{0}': maxResume })}
                        onClose={closeHint}
                        description={trls[TrlKeys.maxResumesHint]}
                        icon={<InfoCircleFilledSize24 />}
                        iconStyle="neutral"
                        buttons={
                            <Button stretched size="medium" style="accent" mode="primary" onClick={closeHint}>
                                {trls[TrlKeys.maxResumesButton]}
                            </Button>
                        }
                    />
                </>
            ) : (
                <Tooltip
                    showClose
                    aria-label-close={trls[TrlKeys.maxResumesCloseAreaLabel]}
                    closeByClickOutside
                    visible={maxResumeHintVisible}
                    onClose={() => setMaxResumeHintVisible(false)}
                    placement="bottom-right"
                    activatorRef={createButtonRef}
                >
                    <p>{format(trls[TrlKeys.maxResumesTitle], { '{0}': maxResume })}</p>
                    <p>{trls[TrlKeys.maxResumesHint]}</p>
                </Tooltip>
            )}
        </>
    );

    const findJobFasterButton =
        hasRenewalService && isHH ? (
            <div className="">
                <Button
                    mode={isMobile ? 'tertiary' : 'secondary'}
                    Element={SpaLink}
                    to="/applicant/services/payment?from=myresume&package=basic"
                    data-qa="resumeservice-button__renewresume"
                    onMouseUp={sendAnalyticsFindJobFaster}
                    stretched={isMobile}
                    size="small"
                >
                    {trls[TrlKeys.findJobFaster]}
                </Button>
            </div>
        ) : null;

    const completeResumeButton =
        hasCompleteResume && isHH ? (
            <div className="">
                <Button
                    mode={isMobile ? 'tertiary' : 'secondary'}
                    Element={SpaLink}
                    data-qa="resumeservice-button__completeResume"
                    to="/article/expert_resume?from=myresume"
                    onClick={() => sendAnalyticsCompleteResume(userType)}
                    stretched={isMobile}
                    size="small"
                >
                    {trls[TrlKeys.completeResume]}
                </Button>
            </div>
        ) : null;

    return (
        <div className={styles.applicantNavigation}>
            {isMobile ? (
                <>
                    {createResumeButton}
                    {findJobFasterButton}
                    {completeResumeButton}
                </>
            ) : (
                <>
                    {completeResumeButton}
                    {createResumeButton}
                </>
            )}
            {maxResumesHint}
            <ImportResumeModal
                visible={importZpVisible}
                onClose={() => setImportZpVisible(false)}
                url={'/applicant/resumes/new'}
                activatorRef={createButtonRef}
            />
        </div>
    );
});

export default ApplicantNavigation;
