import PropTypes from 'prop-types';

import Column, { ColumnsRow } from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import { H1 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import JobSearchStatus from 'lux/components/Applicant/JobSearchStatus';
import PhoneVerificationFlow from 'lux/components/Applicant/PhoneVerification/PhoneVerificationFlow';
import ResumeMergeActivator from 'lux/components/Applicant/ResumesMerge/ResumeMergeActivator';
import VacancyResponseCounter from 'lux/components/Applicant/VacancyResponseCounter';
import ShadowContainer from 'lux/components/Applicant/VacancyResponseCounter/ShadowContainer';
import ApplicantNavigation from 'lux/components/ApplicantNavigation';
import ApplicantServicesSlider from 'lux/components/ApplicantServicesSlider/ApplicantServicesSlider';
import ApplicantSideBar from 'lux/components/ApplicantSideBar';
import ContainerForMicroFrontend from 'lux/components/CodeInjector/ContainerForMicroFrontend';
import PageLayout, { SideBarLayout } from 'lux/components/PageLayout';
import ApplicantSkillsVerificationMethodsCard from 'lux/components/ResumeView/ApplicantSkillsVerificationMethodsCard';
import TestFinishModal from 'lux/components/TestCardRank/TestFinishModal';
import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import { useIsApplicantProfileBlocked } from 'lux/models/applicantProfile/moderation';
import { ApplicantSkillsVerificationResultKeys } from 'lux/models/applicantSkillsVerificationResult';
import { useSelector } from 'lux/modules/useSelector';
import ApplicantResumes from 'lux/pages/ApplicantResumes/components';
import ApplicantSkillsVerificationError from 'lux/pages/ApplicantResumes/components/ApplicantSkillsVerificationError';
import DisabledContainer from 'lux/pages/ApplicantResumes/components/DisabledContainer';
import useApplicantResumesNotifications from 'lux/pages/ApplicantResumes/components/useApplicantResumesNotifications';

const ApplicantResumesPage = ({ trls }) => {
    const showProfile = useExperiment('profile_v1', true, false);
    const isProfileBlocked = useIsApplicantProfileBlocked();
    const applicantResumesStatistics = useSelector(({ applicantResumesStatistics }) => applicantResumesStatistics);
    const recommendations = applicantResumesStatistics.recommendationsForAllResumes;
    const resumes = useSelector(({ applicantResumes }) => applicantResumes);

    const hasBanner = useSelector((state) => !!state.microFrontends.codePracticeBanner);

    const applicantSkillsVerificationMethods = useSelector(({ applicantSkillsVerificationMethods }) =>
        applicantSkillsVerificationMethods.filter((method) => method.availability.status === 'AVAILABLE')
    );
    const applicantSkillsVerificationExpiring = useSelector((state) => state.applicantSkillsVerificationExpiring);

    const applicantSkillsVerificationMethodsCard = (isHeadHunterPlatform) => (
        <Column xs="4" s="8" m="8" l="10">
            {((isHeadHunterPlatform && applicantSkillsVerificationMethods?.length > 0) ||
                applicantSkillsVerificationExpiring?.length > 0) && (
                <>
                    <ApplicantSkillsVerificationMethodsCard
                        applicantSkillsVerificationMethods={applicantSkillsVerificationMethods}
                        applicantSkillsVerificationExpiring={applicantSkillsVerificationExpiring}
                    />
                    <VSpacing base={6} />
                </>
            )}
        </Column>
    );

    const skillsVerificationResults = useSelector(
        ({ applicantSkillsVerificationResult }) => applicantSkillsVerificationResult
    );
    const applicantSkillsVerificationError = useSelector(
        ({ applicantSkillsVerificationError }) => applicantSkillsVerificationError
    );
    const latestResume = resumes.sort((a, b) => a.updatedHuman - b.updatedHuman)[0];
    const needVerifiedPhone = recommendations?.needVerifiedPhone;
    const needResponseStreak = recommendations?.needResponseStreak;
    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    useApplicantResumesNotifications();
    return (
        <PageLayout title={trls[ApplicantResumesPage.trls.title]} layout={SideBarLayout} sidebar={ApplicantSideBar}>
            <ColumnsRow>
                <Column xs="4" s="4" m="4" l="5">
                    <H1>{trls[ApplicantResumesPage.trls.title]}</H1>
                </Column>
                <Column xs="0" s="4" m="4" l="5">
                    <ApplicantNavigation />
                </Column>
            </ColumnsRow>
            {showProfile && (
                <>
                    <Column xs="4" s="8" m="8" l="10">
                        <ContainerForMicroFrontend place={'profileBlockedBanner'} />
                    </Column>
                    <Column xs="4" s="8" m="8" l="10">
                        <ContainerForMicroFrontend place={'profileActivator'} />
                    </Column>
                </>
            )}

            <ColumnsRow>
                <Column xs="4" s="8" m="8" l="10">
                    <VSpacing base={6} />
                    <DisabledContainer disabled={isProfileBlocked}>
                        <JobSearchStatus />
                    </DisabledContainer>
                </Column>
                {needVerifiedPhone && !isProfileBlocked && (
                    <>
                        <ShadowContainer>
                            <PhoneVerificationFlow
                                resumes={resumes}
                                initialPhone={recommendations.phone.raw}
                                initialPhoneFormatted={recommendations.phone.formatted}
                                isOnResumeListPage
                            />
                        </ShadowContainer>
                    </>
                )}
                {latestResume && needResponseStreak && (
                    <>
                        <ShadowContainer>
                            <VacancyResponseCounter
                                modifyCssClass={'vacancy-response-counter-resumes'}
                                relatedVacanciesHash={latestResume._attributes.hash}
                                noAnimations
                            />
                        </ShadowContainer>
                    </>
                )}
            </ColumnsRow>
            <Column xs="4" s="8" m="8" l="10">
                <ApplicantServicesSlider />
            </Column>
            <VSpacing base={6} />

            <ResumeMergeActivator />

            <Column xs="4" s="8" m="8" l="10">
                <ApplicantResumes />
            </Column>
            {hasBanner && (
                <Column xs="4" s="8" m="8" l="10">
                    <ContainerForMicroFrontend place={'codePracticeBanner'} />
                    <VSpacing base={6} />
                </Column>
            )}
            {applicantSkillsVerificationMethodsCard(isHeadHunterPlatform)}
            <Column xs="4" s="0" m="0" l="0">
                <Gap top bottom>
                    <ApplicantNavigation createResumeHighlighted />
                </Gap>
            </Column>
            {skillsVerificationResults && (
                <TestFinishModal
                    results={skillsVerificationResults}
                    name={skillsVerificationResults?.items?.[0]?.name}
                    rank={skillsVerificationResults?.items?.[0]?.level?.rank}
                    success={skillsVerificationResults?.items?.[0]?.result.status === 'SUCCESS'}
                    education={Boolean(
                        skillsVerificationResults?.items?.[0]?.[ApplicantSkillsVerificationResultKeys.CourseUrl] &&
                            skillsVerificationResults.method[ApplicantSkillsVerificationResultKeys.BrandingSettings][
                                ApplicantSkillsVerificationResultKeys.CtaButtonEnabled
                            ]
                    )}
                    educationUrl={
                        skillsVerificationResults?.items?.[0]?.[ApplicantSkillsVerificationResultKeys.CourseUrl]
                    }
                    correctAnswers={skillsVerificationResults?.items?.[0]?.result?.score?.actual}
                    questionsAmount={skillsVerificationResults?.items?.[0]?.result?.score?.max}
                    isSkill={skillsVerificationResults?.items?.[0]?.category === 'SKILL'}
                    sourceName={skillsVerificationResults?.method?.source?.name}
                />
            )}
            {applicantSkillsVerificationError && <ApplicantSkillsVerificationError />}
        </PageLayout>
    );
};

ApplicantResumesPage.propTypes = {
    search: PropTypes.string,
    trls: PropTypes.object,
};

ApplicantResumesPage.trls = {
    title: 'resumeList.title',
};

export default translation(ApplicantResumesPage);
