import { ComponentType, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import bannerShown from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_element_shown';
import bannerPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/design_system/banner_primary_button_click';
import Button, { ButtonAppearance, ButtonKind } from 'bloko/blocks/button';
import Column from 'bloko/blocks/column';
import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import ElementShownAnchor from 'lux/components/ElementShownAnchor';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume_merge.activator.title',
    hint: 'resume_merge.activator.hint',
    button: 'resume_merge.activator.button',
};

const ResumeMergeActivator: ComponentType = translation(({ trls }) => {
    const enableResumesMerge = useSelector((state) => state.enableResumesMerge);

    const dispatch = useDispatch();

    const analyticsParams = useMemo(
        () =>
            ({
                analyticsId: 'banner',
                bannerText: trls[TrlKeys.hint],
                bannerTitle: trls[TrlKeys.title],
                primaryButtonText: trls[TrlKeys.button],
                secondaryButtonText: '',
            } as const),
        [trls]
    );

    if (!enableResumesMerge) {
        return null;
    }
    return (
        <ElementShownAnchor fn={(element) => bannerShown(element, analyticsParams)}>
            <Column xs="4" s="8" m="8" l="10">
                <div className={styles.root} data-qa="resumes-merge-banner">
                    <Text size={TextSize.Large} strong>
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={2} />
                    <Text size={TextSize.Medium} importance={TextImportance.Secondary}>
                        {trls[TrlKeys.hint]}
                    </Text>
                    <VSpacing base={2} />
                    <Button
                        appearance={ButtonAppearance.Filled}
                        kind={ButtonKind.Primary}
                        onClick={() => {
                            dispatch(push('/profile/merge'));
                            bannerPrimaryButtonClick(analyticsParams);
                        }}
                    >
                        {trls[TrlKeys.button]}
                    </Button>
                </div>
                <VSpacing base={6} />
            </Column>
        </ElementShownAnchor>
    );
});

export default ResumeMergeActivator;
