import { FC } from 'react';

interface ApplicantSkillsVerificationIconProps {
    iconSource: string;
    alt: string;
    isBig: boolean;
}

const ApplicantSkillsVerificationIcon: FC<ApplicantSkillsVerificationIconProps> = ({ isBig, iconSource, alt }) => {
    return (
        <img
            loading="lazy"
            src={iconSource}
            alt={alt}
            className={`resume-key-skills-verification-row-${isBig ? 'big' : 'small'}-icon`}
        />
    );
};

export default ApplicantSkillsVerificationIcon;
