import React, { ComponentType, ReactElement } from 'react';

import resumeListServiceItemButtonClick, {
    ButtonName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/paid_services/resume_list_service_item_button_click';
import resumeListServiceItemElementShown, {
    ElementName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/paid_services/resume_list_service_item_element_shown';
import { Card, Cell, CellText, VSpacing } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import { sendAnalyticsResumesSlider } from 'Modules/ApplicantAnalytics';
import ElementShownAnchor from 'lux/components/ElementShownAnchor';
import { useSelector } from 'lux/modules/useSelector';

type Props = {
    title: string;
    text: string;
    icon: ReactElement;
    link: string;
    dataAnalytics: string;
};

const ApplicantServicesCard: ComponentType<Props> = ({ title, text, icon, link, dataAnalytics }) => {
    const userType = useSelector(({ userType }) => userType);
    const source = useSelector(({ analyticsParams }) => analyticsParams.hhtmSource);
    const url = urlParser(link);

    url.params = {
        ...url.params,
        from: source,
        hhtmFromLabel: 'cards_applicant_services_slider',
    };

    const handleCardClick = () => {
        resumeListServiceItemButtonClick({ buttonName: dataAnalytics as ButtonName });
        sendAnalyticsResumesSlider(dataAnalytics, userType);
    };

    return (
        <ElementShownAnchor
            fn={(element) => resumeListServiceItemElementShown(element, { elementName: dataAnalytics as ElementName })}
        >
            <Card
                Element={SpaLink}
                to={url.href}
                padding={24}
                borderRadius={24}
                onClick={handleCardClick}
                showBorder
                stretched
                actionCard
            >
                <Cell right={<ChevronRightOutlinedSize16 />}>{icon}</Cell>
                <VSpacing default={16} />
                <Cell>
                    <CellText type="title">{title}</CellText>
                    <CellText type="subtitle">{text}</CellText>
                </Cell>
            </Card>
        </ElementShownAnchor>
    );
};

export default ApplicantServicesCard;
