/* eslint-disable import/prefer-default-export */
import { ApplicantServices } from 'lux/components/ApplicantServices/common';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import { ApplicantResumeItem } from 'lux/models/applicantResumes';
import { EVERYONE, CLIENTS, BLACKLIST } from 'lux/models/resume/resumeAccess.types';
import { useSelector } from 'lux/modules/useSelector';

const ALLOWED_ACCESS_TYPES = [EVERYONE, CLIENTS, BLACKLIST];
const TIME_DELTA_TO_SHOW_PROMO = 1000 * 60 * 60 * 48; // 48 hours
const NOW = Date.now();

function shouldShowFindJobPromo(publishedResumes: ApplicantResumeItem[]): boolean {
    return !!publishedResumes
        .filter(({ _attributes }) => !_attributes.renewal)
        .filter(({ accessType }) =>
            accessType?.[0]?.string ? ALLOWED_ACCESS_TYPES.includes(accessType[0].string) : false
        )
        .find(({ _attributes }) => NOW - new Date(_attributes.moderated).getTime() < TIME_DELTA_TO_SHOW_PROMO);
}

export function useFindJobPromoEnabled(): boolean {
    const hasRenewalService = useSelector(({ applicantPaymentServices }) =>
        applicantPaymentServices.includes(ApplicantServices.ResumeRenewal)
    );
    const publishedResumes = useSelector(({ applicantResumes }) =>
        applicantResumes.filter(({ _attributes }) => _attributes.isSearchable)
    );

    const isHeadHunterPlatform = useIsHeadHunterPlatform();

    return isHeadHunterPlatform && hasRenewalService && shouldShowFindJobPromo(publishedResumes);
}
