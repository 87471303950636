import { FC, useRef, useEffect, PropsWithChildren } from 'react';
import classnames from 'classnames';

import resumeListServiceItemButtonClick, {
    ButtonName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/paid_services/resume_list_service_item_button_click';
import resumeListServiceItemElementShown, {
    ElementName,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/paid_services/resume_list_service_item_element_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import Text, { TextSize, TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import urlParser from 'bloko/common/urlParser';

import { sendAnalyticsResumesSlider } from 'Modules/ApplicantAnalytics';
import { useSelector } from 'lux/modules/useSelector';

const Card: FC<
    {
        title: string;
        text: string;
        classname: string;
        link: string;
        dataAnalytics: string;
    } & PropsWithChildren
> = ({ title, text, classname, link, dataAnalytics }) => {
    const userType = useSelector(({ userType }) => userType);
    const containerRef = useRef<HTMLDivElement>(null);
    const url = urlParser(link);
    url.params = {
        ...url.params,
        from: useSelector((state) => state.analyticsParams.hhtmSource),
        hhtmFromLabel: 'cards_applicant_services_slider',
    };

    useEffect(() => {
        if (containerRef.current) {
            resumeListServiceItemElementShown(containerRef.current, { elementName: dataAnalytics as ElementName });
        }
    }, [dataAnalytics]);

    return (
        <Link
            className="applicant-services-slider-card"
            to={url.href}
            onClick={() => {
                resumeListServiceItemButtonClick({ buttonName: dataAnalytics as ButtonName });
                sendAnalyticsResumesSlider(dataAnalytics, userType);
            }}
        >
            <div className="applicant-services-slider-card-wrapper" ref={containerRef}>
                <div className="applicant-services-slider-card-content">
                    <Text size={TextSize.Medium} strong>
                        {title}
                    </Text>
                    <VSpacing base={1} />
                    <Text importance={TextImportance.Secondary}>{text}</Text>
                </div>
                <div className={classnames('applicant-services-slider-card-image', classname)} />
            </div>
        </Link>
    );
};

export default Card;
