import { useCallback, useContext } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import resumeRecommendationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';
import BlokoLink, { LinkKind } from 'bloko/blocks/link';

import { ApplicantServices } from 'lux/components/ApplicantServices/common';
import translation from 'lux/components/translation';
import useIsClient from 'lux/hooks/useIsClient';
import { useIsZarplataPlatform } from 'lux/hooks/usePlatform';

import BotUpdateResumeContext from 'lux/pages/ApplicantResumes/components/BotUpdateResumeContext';

const UpdateButton = ({
    disabled,
    doUpdate,
    error,
    message,
    renewalTime,
    isAutoRenewalRecommended,
    isButton,
    isRenewalAvailable,
    trls,
}) => {
    const isClient = useIsClient();
    const isRenewalEnabled = renewalTime?.nearestIntervalStartTime;
    const isZp = useIsZarplataPlatform();
    const shouldAutoRenewal = isAutoRenewalRecommended && isRenewalAvailable;

    let displayMessage = null;
    if (error) {
        displayMessage = trls[UpdateButton.trls.error];
    } else if (disabled || isRenewalEnabled) {
        displayMessage = message;
    }

    const { resumeId, recommendationDetails } = useContext(BotUpdateResumeContext);
    const surveyProfession = recommendationDetails?.surveyProfession || '';

    const onClick = useCallback(() => {
        if (isZp && shouldAutoRenewal) {
            return;
        }

        if (doUpdate) {
            doUpdate();
        }

        resumeActionLinkButtonClick({ buttonName: 'resume_update', resumeId });

        if (isAutoRenewalRecommended) {
            resumeRecommendationButtonClick({
                type: 'resumeRenewal',
                resumeId,
                surveyProfession,
            });
        }
    }, [doUpdate, isAutoRenewalRecommended, isZp, surveyProfession, resumeId, shouldAutoRenewal]);

    const renderButton = useCallback(
        () =>
            isButton ? (
                <div className="applicant-resumes-recommendations-button applicant-resumes-recommendations-button_wrapped">
                    <Button
                        kind={ButtonKind.Primary}
                        scale={ButtonScale.Small}
                        stretched
                        onClick={onClick}
                        data-qa="resume-update-button_actions"
                        disabled={
                            (isZp && shouldAutoRenewal) ||
                            (disabled && isRenewalEnabled) ||
                            (!isRenewalAvailable && isAutoRenewalRecommended)
                        }
                    >
                        {shouldAutoRenewal && !isZp
                            ? trls[UpdateButton.trls.autoRenewal]
                            : trls[UpdateButton.trls.update]}
                    </Button>
                </div>
            ) : (
                <span
                    className={classnames({
                        'applicant-resumes-update-disabled-button':
                            (disabled && isRenewalEnabled) || (!isRenewalAvailable && isAutoRenewalRecommended),
                    })}
                >
                    <BlokoLink
                        Element="button"
                        type="button"
                        onClick={onClick}
                        data-qa="resume-update-button_actions"
                        kind={isZp && shouldAutoRenewal ? LinkKind.Secondary : undefined}
                    >
                        {shouldAutoRenewal && !isZp
                            ? trls[UpdateButton.trls.autoRenewal]
                            : trls[UpdateButton.trls.update]}
                    </BlokoLink>
                </span>
            ),
        [
            isButton,
            onClick,
            isZp,
            shouldAutoRenewal,
            disabled,
            isRenewalEnabled,
            isRenewalAvailable,
            isAutoRenewalRecommended,
            trls,
        ]
    );

    return displayMessage ? (
        <HoverTip
            render={() => displayMessage}
            placement={TipPlacement.Right}
            host={isClient ? document.body : null}
            dataQa="resume-update-button-tooltip"
        >
            <div>{renderButton()}</div>
        </HoverTip>
    ) : (
        renderButton()
    );
};

UpdateButton.propTypes = {
    /** Обновление недоступно в данный момент */
    disabled: PropTypes.bool,
    /** Обновить резюме бесплатно без смс */
    doUpdate: PropTypes.func,
    /** Произошла ошибка при обновлении */
    error: PropTypes.bool,
    /** Сообщение о том, почему недоступно обновление */
    message: PropTypes.string,
    /** Диапазон времени ближайшего автообновления */
    renewalTime: PropTypes.shape({
        nearestIntervalStartTime: PropTypes.number,
        nearestIntervalEndTime: PropTypes.number,
    }),
    /** Показывать ли текст "Поднимать автоматически" вместо "Поднять в поиске" */
    isAutoRenewalRecommended: PropTypes.bool,
    /** Подключено ли автообновление */
    renewal: PropTypes.bool,
    /** Переводы */
    trls: PropTypes.object.isRequired,
    /** Кнопка ли это (может быть ссылкой) */
    isButton: PropTypes.bool,
    /** Доступно ли автоподнятие в данном регионе */
    isRenewalAvailable: PropTypes.bool,
};

UpdateButton.defaultProps = {
    doUpdate: () => {},
    disabled: false,
    error: false,
    message: '',
};

UpdateButton.trls = {
    error: 'rb.touch.error',
    update: 'resumeList.update',
    renewal: 'myresume.publish.renewal',
    autoRenewal: 'myresume.publish.autoRenewal',
};

export default connect(({ applicantPaymentServices }) => ({
    isRenewalAvailable: applicantPaymentServices.includes(ApplicantServices.ResumeRenewal),
}))(translation(UpdateButton));
