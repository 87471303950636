import { AxiosResponse } from 'axios';

import fetcher from 'lux/modules/fetcher';

declare global {
    interface FetcherPostApi {
        '/applicant/deleteresume': {
            body: { hash: string; hhtmFrom: string; hhtmSource: string };
            response: { url: string };
            queryParams: { from: string; hhtmFromLabel: string };
        };
    }
}

export const deleteResumeApi = (
    hash: string,
    hhtmFrom: string,
    hhtmSource: string
): Promise<AxiosResponse<{ url: string }>> =>
    fetcher.postFormData(
        '/applicant/deleteresume',
        { hash, hhtmFrom, hhtmSource },
        { params: { from: 'resume-delete', hhtmFromLabel: 'resume-delete' } }
    );
