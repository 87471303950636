import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonAppearance, ButtonScale } from 'bloko/blocks/button';
import Gap from 'bloko/blocks/gap';
import { H3Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'lux/components/translation';

const PROMO_LINK = '/applicant/services/payment?from=myresumes_promo&hhtmFromLabel=myresumes_promo';

const FindJobPromo = ({ trls }) => (
    <Gap top bottom>
        <div className="applicant-resumes-promo">
            <H3Section>{trls[FindJobPromo.trls.title]}</H3Section>
            <VSpacing base={4} />

            <div className="applicant-resumes-promo-description">{trls[FindJobPromo.trls.description]}</div>

            <div className="applicant-resumes-promo-button-desktop">
                <Button
                    Element={Link}
                    to={PROMO_LINK}
                    kind={ButtonKind.Success}
                    appearance={ButtonAppearance.Outlined}
                    scale={ButtonScale.Small}
                >
                    {trls[FindJobPromo.trls.order]}
                </Button>
            </div>

            <div className="applicant-resumes-promo-button-mobile">
                <Button
                    Element={Link}
                    to={PROMO_LINK}
                    kind={ButtonKind.Success}
                    appearance={ButtonAppearance.Outlined}
                    scale={ButtonScale.Small}
                    stretched
                >
                    {trls[FindJobPromo.trls.order]}
                </Button>
            </div>
        </div>
    </Gap>
);

FindJobPromo.propTypes = {
    trls: PropTypes.object,
};

FindJobPromo.trls = {
    title: 'FindJobPromo.title',
    description: 'FindJobPromo.description',
    order: 'FindJobPromo.order',
};

export default translation(FindJobPromo);
