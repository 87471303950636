import { AxiosResponse } from 'axios';

import fetcher from 'lux/modules/fetcher';

declare global {
    interface FetcherPostApi {
        '/shards/resume/edit/visibility': {
            body: { hash: string; accessType: string };
            response: void;
            queryParams: { from: string; hhtmFromLabel: string };
        };
    }
}

export const hideResumeApi = (hash: string): Promise<AxiosResponse<void>> =>
    fetcher.post(
        '/shards/resume/edit/visibility',
        { hash, accessType: 'no_one' },
        { params: { from: 'resume-delete', hhtmFromLabel: 'resume-delete' } }
    );
