/* eslint-disable import/prefer-default-export */
import { FC } from 'react';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResumeUpdate from 'lux/components/ResumeUpdate';
import { ApplicantResumeItem } from 'lux/models/applicantResumes';
import { BLOCKED, NOT_FINISHED } from 'lux/modules/resumeStatuses';
import { RenewalButton } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeButtons/RenewalButton';

import { DuplicateAction } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/DuplicateAction';

type Props = {
    resume: ApplicantResumeItem;
    onUpdate: (time: number) => void;
    onTimeChanged: (time: number) => void;
    isAutoRenewalRecommended: boolean;
};

export const MobileResumeActions: FC<Props> = ({ resume, isAutoRenewalRecommended, onUpdate, onTimeChanged }) => {
    const {
        // eslint-disable-next-line camelcase
        _attributes: { hash, status, update_timeout: updateTimeout, id },
        humanDatesRules,
        toUpdate,
        renewalTime,
    } = resume;
    const resumeId = parseInt(id, 10);

    if (status === BLOCKED) {
        return null;
    }

    if (status === NOT_FINISHED) {
        return <DuplicateAction stretched hash={hash} resumeId={resumeId} />;
    }

    return (
        <ResumeUpdate
            stretched
            hash={hash}
            mode="link"
            timeLeft={toUpdate.value * 1000}
            onUpdate={onUpdate}
            renewalTime={renewalTime}
            onTimeChanged={onTimeChanged}
            RenderComponent={RenewalButton}
            updateInterval={updateTimeout}
            humanDatesRules={humanDatesRules}
            isAutoRenewalRecommended={isAutoRenewalRecommended}
        />
    );
};
