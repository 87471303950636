import classnames from 'classnames';

import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';

interface TestRankProps {
    rank?: number | null;
    background?: boolean;
    success?: boolean;
    name?: string;
    icon?: boolean;
    black?: boolean;
}

const TrlKeys = {
    3: 'tests.card.level.advanced',
    2: 'tests.card.level.middle',
    1: 'tests.card.level.beginner',
};

const TestRank: TranslatedComponent<TestRankProps> = ({
    trls,
    rank,
    background = false,
    success = false,
    name = '',
    icon,
    black,
}) => {
    if (!rank) {
        return <></>;
    }

    return (
        <>
            <div
                className={classnames('skills-verification-methods-rank', {
                    'skills-verification-methods-rank-background': background,
                    'skills-verification-methods-rank-success': success,
                    'skills-verification-methods-rank-black': black,
                })}
                data-qa={'verification-method-level'}
            >
                {icon && (
                    <div
                        className={classnames(
                            'skills-verification-methods-rank-icon-margin',
                            'skills-verification-methods-rank-icon',
                            {
                                [`skills-verification-methods-rank-${rank}-icon`]: true,
                                [`skills-verification-methods-rank-${rank}-success-icon`]: success,
                                [`skills-verification-methods-rank-${rank}-black-icon`]: black,
                            }
                        )}
                    />
                )}
                <Text>{name || trls[TrlKeys[rank as keyof typeof TrlKeys]]}</Text>
            </div>
            <VSpacing base={2} />
        </>
    );
};

export default translation(TestRank);
