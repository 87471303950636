import Analytics, { VendorAnalytics } from '@hh.ru/analytics-js';

import { UserType } from 'lux/models/userType';

export const sendAnalyticsCompleteResume = (userType: UserType): void => {
    VendorAnalytics.eventToStorage(userType, 'myresume_expert_resume', 'order_expert_resume');
};

export const sendAnalyticsResumesSlider = (service: string, userType: UserType): void => {
    VendorAnalytics.eventToStorage(userType, `myresume_${service}`, `get_${service}`);
};

export const sendAnalyticsFindJobFaster = (): void => {
    Analytics.sendEvent('autoservice', 'myresume', 'click_button');
};

export const sendAnalyticsWantWorkHere = (employerId: string): void => {
    Analytics.sendEvent('hochu_zdes_rabotat', 'button_company', employerId);
};

export const sendAnalyticsWatchForVacancies = (): void => {
    Analytics.sendEvent('applicant', 'watch_for_vacancies', 'subscribe_employer_vacancies');
};
