import { useState, useContext } from 'react';
import PropTypes from 'prop-types';

import resumeRecommendationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import Button, { ButtonKind, ButtonScale } from 'bloko/blocks/button';
import HoverTip, { TipPlacement } from 'bloko/blocks/drop/Tip/HoverTip';

import translation from 'lux/components/translation';
import useIsClient from 'lux/hooks/useIsClient';

import BotUpdateResumeContext from 'lux/pages/ApplicantResumes/components/BotUpdateResumeContext';
import UpdateByBotButton from 'lux/pages/ApplicantResumes/components/UpdateByBotButton';

const ResumeRecommendationsUpdateButton = ({ disabled, doUpdate, error, message, trls }) => {
    const [clicked, setClicked] = useState(false);
    const isClient = useIsClient();
    const { successUpdate, resumeId, recommendationDetails, canUpdateResumeByBot } = useContext(BotUpdateResumeContext);

    let displayMessage = null;
    if (error) {
        displayMessage = trls[ResumeRecommendationsUpdateButton.trls.error];
    } else if (disabled) {
        displayMessage = message;
    }

    const caption =
        clicked && disabled
            ? trls[ResumeRecommendationsUpdateButton.trls.updateSuccess]
            : trls[ResumeRecommendationsUpdateButton.trls.update];

    const update = () => {
        setClicked(true);
        doUpdate();
        resumeRecommendationButtonClick({
            type: 'updateResume',
            resumeId,
            surveyProfession: recommendationDetails?.surveyProfession,
        });
    };

    const renderButton = () => {
        return (
            <Button
                kind={ButtonKind.Primary}
                scale={ButtonScale.Small}
                stretched
                onClick={update}
                data-qa="resume-update-button"
                disabled={disabled}
            >
                {caption}
            </Button>
        );
    };

    if (canUpdateResumeByBot && successUpdate) {
        return (
            <div className="applicant-resumes-recommendations-button applicant-resumes-recommendations-button_wrapped">
                <UpdateByBotButton>{trls[ResumeRecommendationsUpdateButton.trls.updateByBotButton]}</UpdateByBotButton>
            </div>
        );
    }

    return (
        <div className="applicant-resumes-recommendations-button applicant-resumes-recommendations-button_wrapped">
            {displayMessage ? (
                <HoverTip
                    render={() => displayMessage}
                    placement={TipPlacement.Right}
                    host={isClient ? document.body : null}
                    dataQa="resume-update-button-tooltip"
                >
                    <span className="applicant-resumes-update-wrapper">{renderButton()}</span>
                </HoverTip>
            ) : (
                renderButton()
            )}
        </div>
    );
};

ResumeRecommendationsUpdateButton.propTypes = {
    /** Обновление недоступно в данный момент */
    disabled: PropTypes.bool,
    /** Обновить резюме бесплатно без смс */
    doUpdate: PropTypes.func,
    /** Произошла ошибка при обновлении */
    error: PropTypes.bool,
    /** Сообщение о том, почему недоступно обновление */
    message: PropTypes.string,
    /** Переводы */
    trls: PropTypes.object.isRequired,
};

ResumeRecommendationsUpdateButton.defaultProps = {
    doUpdate: () => {},
    disabled: false,
    error: false,
    message: '',
};

ResumeRecommendationsUpdateButton.trls = {
    error: 'rb.touch.error',
    update: 'resumeList.recommendations.action.update',
    updateSuccess: 'resumeList.recommendations.action.update.success',
    updateByBotButton: 'botUpdateResume.updateByBotButton',
};

export default translation(ResumeRecommendationsUpdateButton);
