/* eslint-disable import/prefer-default-export */
import { ComponentType } from 'react';

import { Snackbar } from '@hh.ru/magritte-ui';
import { MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';

import { NotificationProps } from 'lux/components/Notifications/Provider/types';
import translation from 'lux/components/translation';

const TrlKeys = {
    text: 'resumesList.notification.resumeLimit',
};

export const MaxLimitNotification: ComponentType<NotificationProps> = translation(({ trls, ...rest }) => (
    <Snackbar addon={<MinusCircleFilledSize24 initial="negative" />} {...rest} showClose>
        {trls[TrlKeys.text]}
    </Snackbar>
));
