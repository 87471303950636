import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import styles from './disabled-container.less';

const DisabledContainer: FC<PropsWithChildren<{ disabled?: boolean }>> = ({ children, disabled = false }) => {
    if (disabled) {
        return (
            <div
                className={classnames({
                    [styles.disabled]: disabled,
                })}
            >
                {children}
            </div>
        );
    }

    return <>{children}</>;
};

export default DisabledContainer;
