import { ComponentType, FC, PropsWithChildren } from 'react';

import { GridLayout, GridColumn, GridRow, VSpacing } from '@hh.ru/magritte-ui';

interface SideBarLayoutProps {
    sidebar: ComponentType<{ isMagritte?: boolean }>;
}

const MagritteSideBarLayout: FC<SideBarLayoutProps & PropsWithChildren> = ({ children, sidebar: SideBarComponent }) => (
    <GridLayout>
        <VSpacing default={24} gteS={40} />
        <GridRow>
            <GridColumn xs={4} s={8} m={8} l={8} xl={8} xxl={8}>
                {children}
            </GridColumn>
            <GridColumn xs={0} s={0} m={1} l={1} xl={1} xxl={1} />
            <GridColumn xs={4} s={8} m={3} l={3} xl={3} xxl={3}>
                <SideBarComponent isMagritte />
            </GridColumn>
        </GridRow>
    </GridLayout>
);

export default MagritteSideBarLayout;
