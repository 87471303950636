import { useRef, useEffect } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useSelector } from 'lux/modules/useSelector';

import Counter from 'lux/components/Applicant/VacancyResponseCounter/Counter';

const VacancyResponseCounter = ({ modifyCssClass = '', relatedVacanciesHash, noAnimations = false }) => {
    const counterRef = useRef();
    const { responsesCount, responsesRequired } = useSelector(
        ({ applicantResumesStatistics }) => applicantResumesStatistics.recommendationsForAllResumes
    );

    useEffect(() => {
        if (!noAnimations) {
            counterRef.current.classList.remove('g-hidden');
        }
    }, [noAnimations]);

    if (!responsesCount && !responsesRequired) {
        return null;
    }

    return (
        <div className={classnames({ 'g-hidden': !noAnimations })} ref={counterRef}>
            <Counter
                modifyCssClass={modifyCssClass}
                responsesCount={responsesCount}
                responsesRequired={responsesRequired}
                noAnimations={noAnimations}
                relatedVacanciesHash={relatedVacanciesHash}
            />
        </div>
    );
};

VacancyResponseCounter.propTypes = {
    modifyCssClass: PropTypes.string,
    relatedVacanciesHash: PropTypes.string,
    noAnimations: PropTypes.bool,
};

export default VacancyResponseCounter;
