import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';

const Container = ({ children }) => {
    return (
        <Column xs="4" s="8" m="8" l="10" container>
            <Column xs="4" s="8" m="8" l="10">
                <div className={'vacancy-response-counter-shadow'}>{children}</div>
            </Column>
        </Column>
    );
};

Container.propTypes = {
    children: PropTypes.node,
};

export default Container;
