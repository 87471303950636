import { useMemo, useState } from 'react';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import startOfToday from 'date-fns/startOfToday';

import { HoverTip, TipProvider } from 'bloko/blocks/drop';
import DropInfoInline from 'bloko/blocks/drop/InfoInline';
import { QuestionMarkScaleSmall, ExclamationScaleSmallShapeTriangle, IconColor } from 'bloko/blocks/icon';
import Text, { TextSize } from 'bloko/blocks/text';
import format from 'bloko/common/format';
import useBreakpoint from 'bloko/common/hooks/useBreakpoint';
import { LangTrls, TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { Breakpoint } from 'bloko/common/media';

import formatDate from 'Modules/formatDate';
import translation from 'lux/components/translation';
import useIsClient from 'lux/hooks/useIsClient';

import styles from './styles.less';

const TrlKeys = {
    expired: 'applicantSkillsVerification.expired',
    expiredMulti: 'applicantSkillsVerification.expiredMulti',
    expiredOrExpiresMulti: 'applicantSkillsVerification.expiredOrExpiresMulti',
    expires: 'applicantSkillsVerification.expires',
    expiresMulti: 'applicantSkillsVerification.expiresMulti',
    tooltip: 'applicantSkillsVerification.tooltip',
};
interface OutdatedSkillProps {
    expirationDates: string[];
    small?: boolean;
    multi?: boolean;
}

const getOutdatedText = (dates: string[], trls: LangTrls, isMulti: boolean) => {
    if (!dates.length) {
        return null;
    }

    let isExpired = false;
    let isGrace = false;
    for (const date of dates) {
        const expirationDateStart = startOfDay(new Date(date));

        if (isBefore(expirationDateStart, startOfToday())) {
            isExpired = true;
        } else {
            isGrace = true;
        }
        if (isExpired && isGrace) {
            break;
        }
    }
    if (isMulti) {
        if (isExpired && isGrace) {
            return trls[TrlKeys.expiredOrExpiresMulti];
        } else if (isGrace) {
            return trls[TrlKeys.expiresMulti];
        } else if (isExpired) {
            return trls[TrlKeys.expiredMulti];
        }
    } else if (isExpired) {
        return trls[TrlKeys.expired];
    }

    return format(trls[TrlKeys.expires], { '{0}': formatDate(new Date(dates[0]), 'dd MMMM') });
};
const OutdatedSkill: TranslatedComponent<OutdatedSkillProps> = (props) => {
    const { trls, expirationDates, small = false, multi = false } = props;
    const isClient = useIsClient();
    const [showHint, setShowHint] = useState<boolean>(false);
    const isXS = useBreakpoint() === Breakpoint.XS;
    const text = useMemo(() => getOutdatedText(expirationDates, trls, multi), [expirationDates, multi, trls]);

    const handleHintClick = () => isXS && setShowHint(true);
    const handleHintClose = () => setShowHint(false);

    if (!expirationDates.length) {
        return null;
    }

    return (
        <DropInfoInline show={showHint && isXS} render={() => trls[TrlKeys.tooltip]} onClose={handleHintClose}>
            <div className={styles.outdated}>
                <ExclamationScaleSmallShapeTriangle initial={IconColor.Orange60}></ExclamationScaleSmallShapeTriangle>
                <div className={styles.text}>
                    <Text size={small ? TextSize.Small : TextSize.Medium}>{text}</Text>
                </div>
                <TipProvider>
                    <HoverTip show={!isXS} render={() => trls[TrlKeys.tooltip]} host={isClient ? document.body : null}>
                        <QuestionMarkScaleSmall initial={IconColor.Gray40} onClick={handleHintClick} />
                    </HoverTip>
                </TipProvider>
            </div>
        </DropInfoInline>
    );
};

export default translation(OutdatedSkill);
