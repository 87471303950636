import { CurrencyType } from 'lux/models/currencies.types';

export enum ResumeAuditStatus {
    Waiting = 1,
    Finished = 2,
    Archived = 3,
}

export enum ApplicantService {
    ResumeAudit = 'RESUME_AUDIT',
}

export const APPLICANT_SERVICE_ORDER_URL = {
    [ApplicantService.ResumeAudit]: 'resume_audit',
};

export interface ResumeAuditOrder {
    resume?: { hash: string; name: string };
    status: ResumeAuditStatus;
    dueDate?: number;
    orderCode: string;
    activationTime: number;
}

export interface ResumeAuditPrice {
    amount: number;
    currency: CurrencyType;
}

export interface ResumeAuditData {
    price: ResumeAuditPrice;
    orders: ResumeAuditOrder[];
    applicableResumes: string[];
}
