import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';

import translation from 'lux/components/translation';
import paths from 'lux/modules/routePaths';

const VacancyResponseCounterFooter = ({ hash, trls }) => (
    <div className={'vacancy-response-counter-recommendations'}>
        <BlokoLink
            Element={Link}
            disableVisited
            to={`${paths.vacancySearch}?resume=${hash}&from=resumelist`}
            onClick={() => Analytics.sendHHEventButtonClick('vacancy-response-counter-suitable')}
        >
            {trls[VacancyResponseCounterFooter.trls.relatedVacancies]}
        </BlokoLink>
    </div>
);

VacancyResponseCounterFooter.propTypes = {
    hash: PropTypes.string,
    trls: PropTypes.object,
};

VacancyResponseCounterFooter.trls = {
    relatedVacancies: 'vacancy.counter.responses.relatedVacancies',
};

export default translation(VacancyResponseCounterFooter);
