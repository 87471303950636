import { useEffect } from 'react';
import { Primitive } from 'type-fest';

import urlParser from 'bloko/common/urlParser';

import { resumeSaveError, resumeSaveSuccess } from 'lux/components/Notifications/ApplicantResumes';
import { useNotification } from 'lux/components/Notifications/Provider';
import { useSelector } from 'lux/modules/useSelector';

export const UNIVERSITY_SAVED_PARAM = 'university_mapping_saved';

const useApplicantResumesNotifications = (): void => {
    const search = useSelector(({ router }) => router.location.search);
    const { addNotification, removeNotification } = useNotification();

    useEffect(() => {
        const { error, result } = urlParser(search).params;

        if (error && (error as Primitive[])?.length && (error as Primitive[])[0] !== undefined) {
            addNotification(resumeSaveError);
        }

        if (result && (result as Primitive[])?.length && (result as Primitive[])[0] === UNIVERSITY_SAVED_PARAM) {
            addNotification(resumeSaveSuccess);
        }

        return () => {
            removeNotification(resumeSaveError);
            removeNotification(resumeSaveSuccess);
        };
    }, [addNotification, removeNotification, search]);
};

export default useApplicantResumesNotifications;
