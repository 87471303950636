import React, { ComponentType } from 'react';

import { CheckableCard, Text, Radio, Cell, useBreakpoint } from '@hh.ru/magritte-ui';

import translation from 'lux/components/translation';
import { JobSearchStatus, JobSearchStatusSetted } from 'lux/models/applicantUserStatuses';

const TrlKeys = {
    [JobSearchStatus.ActiveSearch]: 'jobSearchStatus.change.version2.active_search',
    [JobSearchStatus.LookingForOffers]: 'jobSearchStatus.change.version2.looking_for_offers',
    [JobSearchStatus.HasJobOffer]: 'jobSearchStatus.change.version2.has_job_offer',
    [JobSearchStatus.AcceptedJobOffer]: 'jobSearchStatus.change.version2.accepted_job_offer',
    [JobSearchStatus.NotLookingForJob]: 'jobSearchStatus.change.version2.not_looking_for_job',
};

type Props = {
    value: JobSearchStatusSetted;
    checkedValue: JobSearchStatus;
    onValueUpdate: (value: JobSearchStatus) => void;
};

const JobSearchStatusOption: ComponentType<Props> = translation(({ trls, value, checkedValue, onValueUpdate }) => {
    const { isMobile } = useBreakpoint();

    const isChecked = checkedValue === value;
    const radioElement = <Radio checked={isChecked} />;
    const cellProps = isMobile ? { right: radioElement } : { left: radioElement };

    return (
        <CheckableCard
            padding={16}
            borderRadius={12}
            type="radio"
            flexible
            value={value}
            checked={isChecked}
            onChange={() => {
                onValueUpdate(value);
            }}
            labelDataQa={`job-search-change_${value}`}
        >
            <Cell {...cellProps}>
                <Text typography="label-2-regular">{trls[TrlKeys[value]]}</Text>
            </Cell>
        </CheckableCard>
    );
});

export default JobSearchStatusOption;
