import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import resumeRecommendationButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import resumeRecommendationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_element_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind, ButtonScale, ButtonAppearance } from 'bloko/blocks/button';
import { format } from 'bloko/common/trl';

import ResumeUpdate from 'lux/components/ResumeUpdate';
import SuitableVacanciesText from 'lux/components/SuitableVacanciesText';
import translation from 'lux/components/translation';
import { ResumeRecommendationVariants, resumeRecommendationType } from 'lux/modules/resumeRecommendations';
import { BLOCKED, resumeStatusType } from 'lux/modules/resumeStatuses';
import paths from 'lux/modules/routePaths';
import { humanDatesRulesType } from 'lux/utils/humanDatesRules';

import ResumeAudit from 'lux/pages/ApplicantResumes/components/ResumeAudit';
import ResumeRecommendationsUpdateButton from 'lux/pages/ApplicantResumes/components/ResumeRecommendationsUpdateButton';
import UpdateButton from 'lux/pages/ApplicantResumes/components/UpdateButton';
import { toUpdateType } from 'lux/pages/ApplicantResumes/components/resumeToUpdate';

const ResumeButtons = ({
    resume,
    suitableVacanciesCount,
    url,
    onUpdate,
    recommendation,
    recommendationDetails,
    onTimeChanged = () => {},
    isAutoRenewalRecommended,
    trls,
}) => {
    const resumeHash = resume?._attributes?.hash;
    const resumeId = resume?._attributes?.id;
    const recommendationRef = useRef();

    useEffect(() => {
        if (recommendationRef.current) {
            resumeRecommendationElementShown(recommendationRef.current, {
                type: recommendation,
                resumeId,
                surveyProfession: recommendationDetails?.surveyProfession,
            });
        }
    }, [recommendation, recommendationDetails, resumeId]);

    const sendRecommendationClickAnalytics = () => {
        resumeRecommendationButtonClick({
            type: recommendation,
            resumeId,
            surveyProfession: recommendationDetails?.surveyProfession,
        });
    };

    const handleSuitableVacanciesClick = () => {
        if (recommendation === ResumeRecommendationVariants.Respond) {
            resumeRecommendationButtonClick({
                type: recommendation,
                resumeId,
                surveyProfession: recommendationDetails?.surveyProfession,
            });
        } else {
            resumeActionLinkButtonClick({ buttonName: 'resume_vacancy_search_list', resumeId });
        }
    };

    const { _attributes, humanDatesRules, toUpdate, renewalTime } = resume;
    const { status, hash, isSearchable } = _attributes;

    const renderButton = ({ caption, ...props }) => {
        return (
            <div
                ref={recommendationRef}
                className="applicant-resumes-recommendations-button applicant-resumes-recommendations-button_wrapped"
            >
                <Button
                    Element={Link}
                    kind={ButtonKind.Primary}
                    scale={ButtonScale.Small}
                    stretched
                    onClick={sendRecommendationClickAnalytics}
                    data-qa={`resume-recommendations__button_${recommendation}`}
                    {...props}
                >
                    {caption}
                </Button>
            </div>
        );
    };

    return (
        <div className="applicant-resumes-recommendations applicant-resumes-recommendations_full-width">
            <div className="applicant-resumes-recommendations-buttons applicant-resumes-recommendations-buttons_wrapped">
                {recommendation === ResumeRecommendationVariants.NeedSkillSurvey && (
                    <div>
                        {renderButton({
                            caption: format(trls[ResumeButtons.trls.undergoSkillsSurveyBase], {
                                '{0}': recommendationDetails.translations.resumeStartSurveyProfession,
                            }),
                            to:
                                `/skills_survey/applicant?surveyProfession=` +
                                `${recommendationDetails.surveyProfession}&source=${resumeId}`,
                            kind: ButtonKind.Primary,
                            'data-qa': 'undergo-skill-survey',
                        })}
                    </div>
                )}
                {recommendation === ResumeRecommendationVariants.NeedVerifiedPhone && (
                    <div>
                        {renderButton({
                            caption: trls[ResumeButtons.trls.verifyPhoneSubmit],
                            to: `/applicant/phone_verification?resume=${resumeHash}`,
                            kind: ButtonKind.Warning,
                            'data-qa': 'resume-phone-not-verified',
                        })}
                    </div>
                )}
                {(recommendation === ResumeRecommendationVariants.EditResume ||
                    recommendation === ResumeRecommendationVariants.OnModeration) &&
                    renderButton({
                        caption: trls[ResumeButtons.trls.actionEditResume],
                        to:
                            isSearchable || status === BLOCKED
                                ? `/resume/${hash}`
                                : `/applicant/resumes/short?resume=${hash}`,
                    })}
                {recommendation === ResumeRecommendationVariants.UpdateResume && (
                    <ResumeUpdate
                        hash={hash}
                        humanDatesRules={humanDatesRules}
                        onUpdate={onUpdate}
                        onTimeChanged={onTimeChanged}
                        RenderComponent={ResumeRecommendationsUpdateButton}
                        timeLeft={toUpdate.value * 1000}
                        updateInterval={_attributes.update_timeout}
                        renewalTime={renewalTime}
                    />
                )}
                {recommendation === ResumeRecommendationVariants.ChangeVisibility &&
                    renderButton({
                        caption: trls[ResumeButtons.trls.actionChangeVisibility],
                        to: `/applicant/resumes/edit/visibility?backUrl=${url}&resume=${hash}`,
                    })}
                {recommendation === ResumeRecommendationVariants.CorrectResume &&
                    renderButton({
                        caption: trls[ResumeButtons.trls.actionCorrectResume],
                        to: `/applicant/resumes/completion?resume=${hash}`,
                        kind: ButtonKind.Warning,
                        'data-qa': 'resume-status-blocked',
                    })}
                {recommendation === ResumeRecommendationVariants.Respond && (
                    <ResumeUpdate
                        hash={hash}
                        humanDatesRules={humanDatesRules}
                        onUpdate={onUpdate}
                        onTimeChanged={onTimeChanged}
                        isAutoRenewalRecommended={isAutoRenewalRecommended}
                        RenderComponent={UpdateButton}
                        timeLeft={toUpdate.value * 1000}
                        updateInterval={_attributes.update_timeout}
                        renewalTime={renewalTime}
                        isButton
                    />
                )}
                {renderButton({
                    caption: <SuitableVacanciesText count={suitableVacanciesCount} />,
                    to: `${paths.vacancySearch}?resume=${hash}&from=resumelist`,
                    kind: ButtonKind.Primary,
                    appearance: ButtonAppearance.Outlined,
                    onClick: handleSuitableVacanciesClick,
                    forceHhtmForInternalLink: true,
                })}
                <ResumeAudit resumeHash={resumeHash} resumeId={resumeId} buttonOnly />
            </div>
        </div>
    );
};

ResumeButtons.propTypes = {
    trls: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    suitableVacanciesCount: PropTypes.number.isRequired,
    skillsSurveyTrls: PropTypes.shape({
        resumeStartSurveyProfession: PropTypes.string,
    }),
    onUpdate: PropTypes.func.isRequired,
    onTimeChanged: PropTypes.func,
    isAutoRenewalRecommended: PropTypes.bool,
    recommendation: resumeRecommendationType,
    recommendationDetails: PropTypes.shape({
        surveyProfession: PropTypes.string,
        translations: PropTypes.shape({
            resumeStartSurveyProfession: PropTypes.string,
            resumeRecommendationTextProfession: PropTypes.string,
        }),
    }),
    resume: PropTypes.shape({
        _attributes: PropTypes.shape({
            hash: PropTypes.string.isRequired,
            id: PropTypes.string,
            status: resumeStatusType.isRequired,
            update_timeout: PropTypes.number.isRequired, // eslint-disable-line camelcase
            updated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            isSearchable: PropTypes.bool.isRequired,
        }).isRequired,
        humanDatesRules: humanDatesRulesType,
        toUpdate: toUpdateType.isRequired,
        renewalTime: PropTypes.object,
    }).isRequired,
};

ResumeButtons.trls = {
    actionEditResume: 'resumeList.recommendations.action.edit',
    actionChangeVisibility: 'resumeList.recommendations.action.visibility',
    actionCorrectResume: 'resumeList.recommendations.action.correct',
    verifyPhoneSubmit: 'resumes.phoneVerification.resumeToAuth.submitPhone',
    undergoSkillsSurveyBase: 'resumeList.recommendations.text.startSurvey.base',
    suitableVacancies: 'resumeList.actionline.suitableVacancies',
};

export const ResumeRecommendationsRaw = translation(ResumeButtons);
export const mapStateToProps = (state) => ({
    url: state.request.url,
    suitableVacancyByResume: state.applicantSuitableVacancyByResume,
});
export const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const suitableVacanciesCount = stateProps.suitableVacancyByResume[ownProps.resume._attributes.hash] || 0;

    return {
        ...ownProps,
        ...dispatchProps,
        ...stateProps,
        suitableVacanciesCount,
    };
};

export default connect(mapStateToProps, null, mergeProps)(ResumeRecommendationsRaw);
