import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

import { Alert, Button as MagritteButton } from '@hh.ru/magritte-ui';
import { InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind, ButtonScale, ButtonType } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import BlokoLink from 'bloko/blocks/link';
import Modal, { ModalContent, ModalFooter, ModalHeader, ModalTitle } from 'bloko/blocks/modal';
import Text, { TextSize } from 'bloko/blocks/text';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import useExperiment from 'lux/hooks/useExperiment';
import { refreshApplicantSkillsVerificationError } from 'lux/models/applicantSkillsVerificationError';
import paths from 'lux/modules/routePaths';

const TrlKeys = {
    title: 'applicantSkillsVerificationMethods.error.title',
    text: 'applicantSkillsVerificationMethods.error.text',
    resultGoVacancies: 'applicantSkillsVerificationMethods.resultGoVacancies',
    close: 'applicantSkillsVerificationMethods.close',
    ok: 'applicantSkillsVerificationMethods.ok',
    magriteTitle: 'applicantSkillsVerificationMethods.magriteTitle',
};
const ApplicantSkillsVerificationError: TranslatedComponent = ({ trls }) => {
    const isMagritteExp = useExperiment('magritte_resume_list');
    const dispatch = useDispatch();
    const [isVisible, setVisible] = useState(true);

    const handleClick = () => {
        dispatch(refreshApplicantSkillsVerificationError(null));
        setVisible(false);
        dispatch(push('applicant/resumes'));
    };

    if (isMagritteExp) {
        return (
            <Alert
                title={trls[TrlKeys.magriteTitle]}
                description={trls[TrlKeys.text]}
                iconStyle="negative"
                layout="vertical"
                icon={<InfoCircleFilledSize24 />}
                buttons={
                    <MagritteButton mode="primary" style="accent" stretched onClick={handleClick}>
                        {trls[TrlKeys.ok]}
                    </MagritteButton>
                }
                visible={isVisible}
                onClose={handleClick}
            />
        );
    }

    return (
        <Modal visible={isVisible} onClose={handleClick} style={{ maxWidth: '680px' }}>
            <ModalHeader>
                <ModalTitle>{trls[TrlKeys.title]}</ModalTitle>
            </ModalHeader>
            <ModalContent>
                <Text size={TextSize.Medium}>{trls[TrlKeys.text]}</Text>
            </ModalContent>
            <ModalFooter>
                <Button type={ButtonType.Button} scale={ButtonScale.Large} onClick={handleClick}>
                    {trls[TrlKeys.close]}
                </Button>
                <HSpacing base={2} />
                <BlokoLink Element={Link} to={`${paths.vacancySearch}?from=resumelist`}>
                    <Button kind={ButtonKind.Primary} scale={ButtonScale.Large} appearance={ButtonAppearance.Filled}>
                        {trls[TrlKeys.resultGoVacancies]}
                    </Button>
                </BlokoLink>
            </ModalFooter>
        </Modal>
    );
};
export default translation(ApplicantSkillsVerificationError);
