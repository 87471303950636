import { Fragment, useCallback, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import { MenuItem } from 'bloko/blocks/drop';
import ClickMenu, { MenuPlacement } from 'bloko/blocks/drop/Menu/Click';
import { EllipsisScaleSmallKindHorizontal, IconLink, IconDynamic, IconColor } from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import requestAnimation from 'bloko/common/requestAnimation';

import ResumeDelete from 'lux/components/ResumeDelete';
import ResumeUpdate from 'lux/components/ResumeUpdate';
import translation from 'lux/components/translation';
import { BLOCKED, NOT_FINISHED, resumeStatusType } from 'lux/modules/resumeStatuses';
import { humanDatesRulesType } from 'lux/utils/humanDatesRules';

import ResumeDuplicate from 'lux/pages/ApplicantResumes/components/ResumeDuplicate';
import ResumeVisibility from 'lux/pages/ApplicantResumes/components/ResumeVisibility';
import UpdateButton from 'lux/pages/ApplicantResumes/components/UpdateButton';
import { toUpdateType } from 'lux/pages/ApplicantResumes/components/resumeToUpdate';

const shouldShowMenu = (ref) => ref.current !== null && ref.current.offsetTop > ref.current.offsetHeight;

const ResumeActions = ({ resumeProps, isAutoRenewalRecommended, onUpdate, onTimeChanged, trls }) => {
    const {
        // eslint-disable-next-line camelcase
        _attributes: { hash, isSearchable, status, update_timeout, id },
        humanDatesRules,
        toUpdate,
        renewalTime,
    } = resumeProps;
    const visibilityRef = useRef(null);
    const duplicateRef = useRef(null);
    const editRef = useRef(null);
    const deleteRef = useRef(null);

    const [showVisibilityMenu, setShowVisibilityMenu] = useState(true);
    const [showDuplicateMenu, setShowDuplicateMenu] = useState(true);
    const [showEditMenu, setShowEditMenu] = useState(true);
    const [showMenu, setShowMenu] = useState(false);

    useLayoutEffect(() => {
        const showMenuIfNeeded = requestAnimation(() => {
            const newShowVisibilityMenu = shouldShowMenu(visibilityRef);
            const newShowDuplicateMenu = shouldShowMenu(duplicateRef);
            const newShowEditMenu = shouldShowMenu(editRef);
            setShowVisibilityMenu(newShowVisibilityMenu);
            setShowDuplicateMenu(newShowDuplicateMenu);
            setShowEditMenu(newShowEditMenu);
            setShowMenu(newShowVisibilityMenu || newShowDuplicateMenu || newShowEditMenu);
        });

        showMenuIfNeeded();
        window.addEventListener('resize', showMenuIfNeeded);

        return () => {
            window.removeEventListener('resize', showMenuIfNeeded);
        };
    }, [isSearchable]);

    const handleEditClick = useCallback(() => {
        resumeActionLinkButtonClick({ buttonName: 'resume_edit', resumeId: id });
    }, [id]);

    const renderDeleteButton = useCallback(
        (handleClick) => (
            <BlokoLink
                Element="button"
                type="button"
                data-qa="resume-delete"
                onClick={() => {
                    handleClick();
                    resumeActionLinkButtonClick({ buttonName: 'resume_delete', resumeId: id });
                }}
            >
                {trls[ResumeActions.trls.delete]}
            </BlokoLink>
        ),
        [id, trls]
    );

    const renderEditButton = useCallback(
        () => (
            <BlokoLink
                Element={Link}
                to={isSearchable || status === BLOCKED ? `/resume/${hash}` : `/applicant/resumes/short?resume=${hash}`}
                disableVisited
                data-qa="resume-edit"
                onClick={handleEditClick}
            >
                {trls[ResumeActions.trls.edit]}
            </BlokoLink>
        ),
        [hash, isSearchable, status, trls, handleEditClick]
    );

    /* eslint-disable camelcase */
    const renderUpdateButton = useCallback(
        () => (
            <ResumeUpdate
                hash={hash}
                humanDatesRules={humanDatesRules}
                onUpdate={onUpdate}
                onTimeChanged={onTimeChanged}
                isAutoRenewalRecommended={isAutoRenewalRecommended}
                RenderComponent={UpdateButton}
                timeLeft={toUpdate.value * 1000}
                updateInterval={update_timeout}
                renewalTime={renewalTime}
            />
        ),
        [
            hash,
            humanDatesRules,
            isAutoRenewalRecommended,
            onTimeChanged,
            onUpdate,
            renewalTime,
            toUpdate.value,
            update_timeout,
        ]
    );
    /* eslint-enable camelcase */

    const renderMenu = useCallback(
        () => (
            <IconDynamic>
                <ClickMenu
                    placement={MenuPlacement.BottomEnd}
                    render={() => (
                        <Fragment>
                            {showVisibilityMenu && (
                                <MenuItem Element="button">
                                    <ResumeVisibility hash={hash} id={id} />
                                </MenuItem>
                            )}
                            {showDuplicateMenu && (
                                <MenuItem>
                                    <ResumeDuplicate hash={hash} id={id} />
                                </MenuItem>
                            )}
                            {showEditMenu && <MenuItem Element="button">{renderEditButton()}</MenuItem>}
                        </Fragment>
                    )}
                >
                    <IconLink Element="button" type="button">
                        <EllipsisScaleSmallKindHorizontal initial={IconColor.Gray40} />
                    </IconLink>
                </ClickMenu>
            </IconDynamic>
        ),
        [hash, showVisibilityMenu, showDuplicateMenu, showEditMenu, renderEditButton, id]
    );

    if (status === BLOCKED) {
        return (
            <>
                <VSpacing base={4} xs={3} />
                <div className="applicant-resumes-actions-wrapper">
                    <div className="applicant-resumes-actions">
                        <div className="applicant-resumes-actions-content">
                            {!isSearchable && (
                                <div className="applicant-resumes-action" ref={deleteRef}>
                                    <ResumeDelete hash={hash} from="resume-list" render={renderDeleteButton} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    if (status === NOT_FINISHED) {
        return (
            <>
                <VSpacing base={4} xs={3} />
                <div className="applicant-resumes-actions-wrapper">
                    <div className="applicant-resumes-actions">
                        <div className="applicant-resumes-actions-content">
                            <div className="applicant-resumes-action" ref={duplicateRef}>
                                <ResumeDuplicate hash={hash} id={id} />
                            </div>
                            {!isSearchable && (
                                <div className="applicant-resumes-action" ref={deleteRef}>
                                    <ResumeDelete hash={hash} from="resume-list" render={renderDeleteButton} />
                                </div>
                            )}
                        </div>
                        {showMenu && <div className="applicant-resumes-actions-more">{renderMenu()}</div>}
                    </div>
                </div>
            </>
        );
    }

    return (
        <>
            <VSpacing base={4} xs={3} />
            <div className="applicant-resumes-actions-wrapper">
                <div className="applicant-resumes-actions">
                    <div className="applicant-resumes-actions-content">
                        <div className="applicant-resumes-action">{renderUpdateButton()}</div>
                        <div className="applicant-resumes-action" ref={visibilityRef}>
                            <ResumeVisibility hash={hash} id={id} />
                        </div>
                        <div className="applicant-resumes-action" ref={editRef}>
                            {renderEditButton()}
                        </div>
                        <div className="applicant-resumes-action" ref={duplicateRef}>
                            <ResumeDuplicate hash={hash} id={id} />
                        </div>
                    </div>
                    {showMenu && <div className="applicant-resumes-actions-more">{renderMenu()}</div>}
                </div>
            </div>
        </>
    );
};

ResumeActions.propTypes = {
    resumeProps: PropTypes.shape({
        _attributes: PropTypes.shape({
            hash: PropTypes.string.isRequired,
            isSearchable: PropTypes.bool.isRequired,
            status: resumeStatusType.isRequired,
            update_timeout: PropTypes.number.isRequired, // eslint-disable-line camelcase
            id: PropTypes.string.isRequired,
        }).isRequired,
        humanDatesRules: humanDatesRulesType,
        toUpdate: toUpdateType.isRequired,
        renewalTime: PropTypes.object,
    }),
    isAutoRenewalRecommended: PropTypes.bool,
    onUpdate: PropTypes.func.isRequired,
    onTimeChanged: PropTypes.func.isRequired,
    trls: PropTypes.object,
};

ResumeActions.trls = {
    delete: 'resumeList.actionline.delete',
    edit: 'resume.edit',
};

export default translation(ResumeActions);
