import { useCallback } from 'react';
import PropTypes from 'prop-types';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import translation from 'lux/components/translation';

import ResumeStatisticsItem from 'lux/pages/ApplicantResumes/components/ResumeStatisticsItem';

const ResumeStatisticsList = ({ trls, hash, statistics, resumeId }) => {
    const handleViewsClick = useCallback(() => {
        resumeActionLinkButtonClick({ buttonName: 'resume_view_history', resumeId });
    }, [resumeId]);

    const handleInvitationsClick = useCallback(() => {
        resumeActionLinkButtonClick({ buttonName: 'negotiation_list', resumeId });
    }, [resumeId]);

    if (!statistics) {
        return null;
    }

    const statisticsItems = [
        {
            type: 'searchShows',
            ...statistics.searchShows,
        },
        {
            type: 'views',
            link: `/applicant/resumeview/history?resumeHash=${hash}`,
            onClick: handleViewsClick,
            ...statistics.views,
        },
        {
            type: 'invitations',
            link: '/applicant/negotiations?state=INVITATION',
            onClick: handleInvitationsClick,
            ...statistics.invitations,
        },
    ];

    return (
        <>
            <VSpacing base={4} />
            <Text strong>{trls[ResumeStatisticsList.trls.title]}</Text>
            <div className="applicant-resumes-statistics-list applicant-resumes-statistics-list_cutted-width">
                {statisticsItems.map((item) => (
                    <ResumeStatisticsItem key={item.type} {...item} />
                ))}
            </div>
        </>
    );
};

ResumeStatisticsList.propTypes = {
    trls: PropTypes.object,
    hash: PropTypes.string.isRequired,
    statistics: PropTypes.shape({
        searchShows: PropTypes.object,
        views: PropTypes.object,
        invitations: PropTypes.object,
    }),
    resumeId: PropTypes.string.isRequired,
};

ResumeStatisticsList.trls = {
    title: 'resumeList.statistics.text.title',
};

export default translation(ResumeStatisticsList);

export { ResumeStatisticsList as ResumeStatisticsListPure };
