import { ComponentProps, useCallback, useMemo, useState } from 'react';

import { ApplicantSkillsVerificationMethod } from 'lux/models/applicantSkillsVerificationMethods';

import ApplicantSkillsVerificationMethodModal from 'lux/components/ApplicantSkillsVerificationMethodModal/ApplicantSkillsVerificationMethodModal';

type OpenMethodModalParams = {
    method: ApplicantSkillsVerificationMethod;
};

type UsedApplicantSkillsVerificationMethodModal = {
    applicantSkillsVerificationMethodModalProps: ComponentProps<typeof ApplicantSkillsVerificationMethodModal>;
    openApplicantSkillsVerificationMethodModal: (params: OpenMethodModalParams) => void;
};

const useApplicantSkillsVerificationMethodModal = (): UsedApplicantSkillsVerificationMethodModal => {
    const [isVisible, setIsVisible] = useState(false);
    const [openMethodModalParams, setOpenMethodModalParams] = useState<OpenMethodModalParams | undefined>();

    const openApplicantSkillsVerificationMethodModal = useCallback((params: OpenMethodModalParams) => {
        setOpenMethodModalParams(params);
        setIsVisible(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setIsVisible(false);
        setOpenMethodModalParams(undefined);
    }, []);

    const applicantSkillsVerificationMethodModalProps = useMemo(
        () => ({
            visible: isVisible,
            onClose: handleModalClose,
            method: openMethodModalParams?.method,
        }),
        [isVisible, handleModalClose, openMethodModalParams]
    );

    return {
        applicantSkillsVerificationMethodModalProps,
        openApplicantSkillsVerificationMethodModal,
    };
};

export default useApplicantSkillsVerificationMethodModal;
