import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { GridColumn, VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';

import bannerPropType from 'lux/components/Banner/BannerPropType';

import Clickme from 'lux/components/Clickme';
import ClickmeWrapper from 'lux/components/Clickme/Wrapper';

const HIDE_ON_XS_SCREEN_POSITION = new Set([1, 2]);
const HIDE_ON_S_SCREEN_POSITION = 2;

const List = ({ banners, clickmeReferralLink, isMagritte }) => {
    if (!banners || banners.length === 0) {
        return null;
    }

    if (isMagritte) {
        return (
            <ClickmeWrapper>
                {clickmeReferralLink}
                <Clickme banners={banners}>
                    {banners.map(({ id }, index) => (
                        <GridColumn
                            key={id}
                            xs={HIDE_ON_XS_SCREEN_POSITION.has(index) ? 0 : 4}
                            s={index === HIDE_ON_S_SCREEN_POSITION ? 0 : 4}
                            m={4}
                            l={3}
                        >
                            <div className="HHC-Group-Banners-Place" data-banner-id={id} />
                        </GridColumn>
                    ))}
                </Clickme>
                <VSpacing default={24} />
            </ClickmeWrapper>
        );
    }

    return (
        <ClickmeWrapper>
            <Gap bottom>
                {clickmeReferralLink}
                <Clickme banners={banners}>
                    {banners.map(({ id }, index) => (
                        <Column
                            key={id}
                            xs={HIDE_ON_XS_SCREEN_POSITION.has(index) ? '0' : '4'}
                            s={index === HIDE_ON_S_SCREEN_POSITION ? '0' : '4'}
                            m="4"
                            l="5"
                        >
                            <div className="HHC-Group-Banners-Place" data-banner-id={id} />
                        </Column>
                    ))}
                </Clickme>
            </Gap>
        </ClickmeWrapper>
    );
};

List.propTypes = {
    banners: PropTypes.arrayOf(bannerPropType),
    clickmeReferralLink: PropTypes.node,
    isMagritte: PropTypes.bool,
};

export default connect(
    (state) => ({ banners: state.banners }),
    undefined,
    (stateProps, dispatchProps, ownProps) => ({
        banners: stateProps.banners['right-column-clickme'],
        ...ownProps,
        ...dispatchProps,
    })
)(List);
