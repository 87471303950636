import { createContext, useContext } from 'react';

export interface Payload {
    hash: string;
    from: string;
}

type ResumeDeleteContext = {
    visible: boolean;
    openModal: (payload: Payload) => void;
    closeModal: () => void;
    hideResume: () => void;
    deleteResume: () => void;
};

export const ResumeDeleteModalContext = createContext<ResumeDeleteContext>({
    visible: false,
    openModal: () => void 0,
    closeModal: () => void 0,
    hideResume: () => void 0,
    deleteResume: () => void 0,
});

export const useResumeDeleteModalContext = (): ResumeDeleteContext => useContext(ResumeDeleteModalContext);
