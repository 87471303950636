import { useMemo } from 'react';

import { ResumeTags } from 'lux/modules/resumeStatuses';
import { useSelector } from 'lux/modules/useSelector';

export function usePreparingByExpert(tags: string[] = []): boolean {
    const showResumeBlockedWhileExpertIsWorking = useSelector(
        (state) => (state.features?.show_resume_blocked_while_expert_is_working as boolean) || false
    );
    return useMemo(
        () => showResumeBlockedWhileExpertIsWorking && tags.includes(ResumeTags.ExpertIsWorking),
        [tags, showResumeBlockedWhileExpertIsWorking]
    );
}
