import { useState, useCallback, useEffect, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import isTomorrow from 'date-fns/isTomorrow';
import { MOVED_TEMPORARILY } from 'http-status-codes';
import PropTypes from 'prop-types';

import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { IntervalMessages } from 'Utils/Dates';
import translation from 'lux/components/translation';
import { useSelector } from 'lux/modules/useSelector';
import { humanDatesRulesType } from 'lux/utils/humanDatesRules';

import TouchModule from 'lux/components/ResumeUpdate/TouchModule';

const ResumeUpdate = ({
    hash,
    humanDatesRules,
    onUpdate,
    onTimeChanged,
    RenderComponent,
    timeLeft,
    trls,
    updateInterval,
    renewalTime,
    isRenewalAvailable,
    ...renderComponentProps
}) => {
    const touch = useRef();
    const dispatch = useDispatch();
    const intervalMessages = useMemo(() => IntervalMessages(humanDatesRules), [humanDatesRules]);
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);
    const [currentTimeLeft, setCurrentTimeLeft] = useState(timeLeft);
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    useEffect(() => {
        setCurrentTimeLeft(timeLeft);
    }, [timeLeft]);

    const disabled = currentTimeLeft > 0;
    const message = disabled ? intervalMessages.get(currentTimeLeft) : null;
    let displayMessage = message ? format(trls[ResumeUpdate.trls.nextTouchWithRenewal], { '{0}': message }) : '';
    if (!isRenewalAvailable) {
        displayMessage = format(trls[ResumeUpdate.trls.nextTouchWithoutRenewal], { '{0}': message });
    }
    if (renewalTime?.nearestIntervalStartTime && renewalTime?.nearestIntervalEndTime) {
        let dateTrl = trls[ResumeUpdate.trls.renewalToday];
        if (isTomorrow(renewalTime.nearestIntervalStartTime)) {
            dateTrl = trls[ResumeUpdate.trls.renewalTomorrow];
        }
        displayMessage = format(trls[ResumeUpdate.trls.renewal], {
            '{0}': dateTrl,
            '{1}': formatDate(renewalTime.nearestIntervalStartTime, 'HH:mm'),
            '{2}': formatDate(renewalTime.nearestIntervalEndTime, 'HH:mm'),
        });
    }

    const doUpdate = useCallback(() => {
        if (pending) {
            return;
        }
        setError(false);
        if (!disabled && touch.current) {
            setPending(true);
            touch.current.send();
        } else if (touch.current) {
            window.open(
                `/applicant/services/findjob?from=resume_actions&hhtmFrom=${hhtmSource}&hhtmFromLabel=resume_actions&resumeHash=${hash}`,
                '_blank'
            );
        }
    }, [disabled, hash, hhtmSource, pending]);

    useEffect(() => {
        touch.current = TouchModule({
            hash,
            timeLeft,
            updateInterval,
            onError: (error) => {
                if (error.response) {
                    const redirectUrl = error.response.data?.url || null;
                    if (error.response.status === MOVED_TEMPORARILY && redirectUrl) {
                        dispatch(push(redirectUrl));
                        return;
                    }
                }
                setError(true);
                setPending(false);
            },
            onSuccess: () => {
                setError(false);
                setPending(false);
                onUpdate(Date.now());
            },
            onTimeLeftChange: (timeLeft) => {
                setError(false);
                setCurrentTimeLeft(timeLeft);
                onTimeChanged(timeLeft);
            },
        });
        return touch.current.destroyHandlers;
    }, [dispatch, hash, onTimeChanged, onUpdate, timeLeft, updateInterval]);
    return (
        <RenderComponent
            disabled={disabled}
            doUpdate={doUpdate}
            error={error}
            hash={hash}
            message={displayMessage}
            renewalTime={renewalTime}
            {...renderComponentProps}
        />
    );
};

ResumeUpdate.propTypes = {
    /** Хеш резюме */
    hash: PropTypes.string.isRequired,
    /** Правила человеческих дат, что бы это ни значило %) */
    humanDatesRules: humanDatesRulesType.isRequired,
    /** Коллбек, вызываемый после обновления резюме с параметром timestamp */
    onUpdate: PropTypes.func,
    /** Коллбек, вызываемый после смены времени до ближайшей возможности поднятия резюме */
    onTimeChanged: PropTypes.func,
    /** Время до следующего обновления, мс */
    timeLeft: PropTypes.number.isRequired,
    /** Переводы */
    trls: PropTypes.object.isRequired,
    /** Минимальный интервал обновления, мс */
    updateInterval: PropTypes.number.isRequired,
    /** Диапазон времени ближайшего автообновления */
    renewalTime: PropTypes.shape({
        nearestIntervalStartTime: PropTypes.number,
        nearestIntervalEndTime: PropTypes.number,
    }),
    /** Компонент для рендеринга */
    RenderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.elementType]),
    /** Пропсы компонента для рендеринга */
    renderComponentProps: PropTypes.object,
    /** Доступно ли автоподнятие в данном регионе */
    isRenewalAvailable: PropTypes.bool,
};

ResumeUpdate.defaultProps = {
    onUpdate: () => {},
    onTimeChanged: () => {},
};

ResumeUpdate.trls = {
    nextTouch: 'myresume.publish.nextTouch',
    renewal: 'myresume.publish.renewal',
    nextTouchWithoutRenewal: 'rb.publish.nextTouch',
    nextTouchWithRenewal: 'myresume.publish.nextTouchWithRenewal',
    renewalToday: 'myresume.publish.renewal.today',
    renewalTomorrow: 'myresume.publish.renewal.tomorrow',
};

export default translation(ResumeUpdate);
