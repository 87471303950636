/* eslint-disable import/prefer-default-export */
import { ReactElement } from 'react';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Button, ButtonStyle, ButtonSize } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import translation from 'lux/components/translation';

const TrlKeys = {
    edit: 'resume.edit',
};

type Props = {
    icon?: ReactElement;
    hash: string;
    href: string;
    size?: ButtonSize;
    style?: ButtonStyle;
    resumeId: number;
    stretched?: boolean;
};

export const EditAction = translation<Props>(
    ({ href, size = 'small', trls, resumeId, icon, stretched = false, style = 'accent' }) => {
        const additionalProps: { icon?: never } | { icon: ReactElement } = { ...(icon && { icon }) };
        return (
            <Button
                Element={Link}
                to={href}
                size={size}
                mode="tertiary"
                style={style}
                data-qa="resume-edit"
                onClick={() => {
                    resumeActionLinkButtonClick({ buttonName: 'resume_edit', resumeId });
                }}
                stretched={stretched}
                {...additionalProps}
            >
                {trls[TrlKeys.edit]}
            </Button>
        );
    }
);
