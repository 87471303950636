/* eslint-disable import/prefer-default-export */
import { useLayoutEffect, useRef, useState, MutableRefObject } from 'react';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Button } from '@hh.ru/magritte-ui';
import requestAnimation from 'bloko/common/requestAnimation';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResumeUpdate from 'lux/components/ResumeUpdate';
import translation from 'lux/components/translation';
import { ApplicantResumeItem } from 'lux/models/applicantResumes';
import { BLOCKED, NOT_FINISHED } from 'lux/modules/resumeStatuses';
import { RenewalButton } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeButtons/RenewalButton';
import { useResumeDeleteModalContext } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/resumeDeleteModal';

import { DesktopActionsMenu } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/ActionsMenu/DesktopActionsMenu';
import { DuplicateAction } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/DuplicateAction';
import { EditAction } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/EditAction';
import { VisibilityAction } from 'lux/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/VisibilityAction';

import styles from './styles.less';

type Props = {
    resume: ApplicantResumeItem;
    onUpdate: (time: number) => void;
    onTimeChanged: (time: number) => void;
    isAutoRenewalRecommended: boolean;
};

const TrlKeys = {
    delete: 'resumeList.actionline.delete',
    edit: 'resume.edit',
};

const shouldShowMenu = (ref: MutableRefObject<null | HTMLDivElement>) =>
    ref.current !== null && ref.current.offsetTop > ref.current.offsetHeight;

export const DesktopResumeActions = translation<Props>(
    ({ resume, isAutoRenewalRecommended, onUpdate, onTimeChanged, trls }) => {
        const {
            // eslint-disable-next-line camelcase
            _attributes: { hash, isSearchable, status, update_timeout: updateTimeout, id },
            humanDatesRules,
            toUpdate,
            renewalTime,
        } = resume;
        const resumeId = parseInt(id, 10);

        const editRef = useRef(null);
        const deleteRef = useRef(null);
        const duplicateRef = useRef(null);
        const visibilityRef = useRef(null);

        const [showMenu, setShowMenu] = useState(false);
        const [showEditMenu, setShowEditMenu] = useState(true);
        const [showDuplicateMenu, setShowDuplicateMenu] = useState(true);
        const [showVisibilityMenu, setShowVisibilityMenu] = useState(true);

        const deleteModalContext = useResumeDeleteModalContext();

        const editUrl =
            isSearchable || status === BLOCKED ? `/resume/${hash}` : `/applicant/resumes/short?resume=${hash}`;
        const isBlocked = status === BLOCKED;

        useLayoutEffect(() => {
            const showMenuIfNeeded = requestAnimation(() => {
                const newShowVisibilityMenu = shouldShowMenu(visibilityRef);
                const newShowDuplicateMenu = shouldShowMenu(duplicateRef);
                const newShowEditMenu = shouldShowMenu(editRef);
                setShowVisibilityMenu(newShowVisibilityMenu);
                setShowDuplicateMenu(newShowDuplicateMenu);
                setShowEditMenu(newShowEditMenu);
                setShowMenu(newShowVisibilityMenu || newShowDuplicateMenu || newShowEditMenu);
            });

            showMenuIfNeeded();
            window.addEventListener('resize', showMenuIfNeeded);

            return () => {
                window.removeEventListener('resize', showMenuIfNeeded);
            };
        }, [isSearchable]);

        if (isBlocked) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.actions}>
                        <div className={styles.content}>
                            {!isSearchable && (
                                <div className={styles.action} ref={deleteRef}>
                                    <Button
                                        size="small"
                                        mode={isBlocked ? 'secondary' : 'tertiary'}
                                        style={isBlocked ? 'negative' : 'accent'}
                                        data-qa="resume-delete"
                                        onClick={() => {
                                            deleteModalContext.openModal({ hash, from: 'resume-list' });
                                            resumeActionLinkButtonClick({ buttonName: 'resume_delete', resumeId });
                                        }}
                                    >
                                        {trls[TrlKeys.delete]}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            );
        }

        if (status === NOT_FINISHED) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.actions}>
                        <div className={styles.content}>
                            <div className={styles.action} ref={duplicateRef}>
                                <DuplicateAction hash={hash} resumeId={resumeId} />
                            </div>
                            {!isSearchable && (
                                <div className={styles.action} ref={deleteRef}>
                                    <Button
                                        size="small"
                                        mode={isBlocked ? 'secondary' : 'tertiary'}
                                        style={isBlocked ? 'negative' : 'accent'}
                                        data-qa="resume-delete"
                                        onClick={() => {
                                            deleteModalContext.openModal({ hash, from: 'resume-list' });
                                            resumeActionLinkButtonClick({ buttonName: 'resume_delete', resumeId });
                                        }}
                                    >
                                        {trls[TrlKeys.delete]}
                                    </Button>
                                </div>
                            )}
                        </div>
                        {showMenu && (
                            <div className={styles.more}>
                                <DesktopActionsMenu
                                    hash={hash}
                                    editUrl={editUrl}
                                    resumeId={resumeId}
                                    showEdit={showEditMenu}
                                    showDuplicate={showDuplicateMenu}
                                    showVisibility={showVisibilityMenu}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.wrapper}>
                <div className={styles.actions}>
                    <div className={styles.content}>
                        <div className={styles.action}>
                            <ResumeUpdate
                                hash={hash}
                                mode="link"
                                timeLeft={toUpdate.value * 1000}
                                onUpdate={onUpdate}
                                renewalTime={renewalTime}
                                onTimeChanged={onTimeChanged}
                                RenderComponent={RenewalButton}
                                updateInterval={updateTimeout}
                                humanDatesRules={humanDatesRules}
                                isAutoRenewalRecommended={isAutoRenewalRecommended}
                            />
                        </div>
                        <div className={styles.action} ref={visibilityRef}>
                            <VisibilityAction hash={hash} resumeId={resumeId} />
                        </div>
                        <div className={styles.action} ref={editRef}>
                            <EditAction hash={hash} href={editUrl} resumeId={resumeId} />
                        </div>
                        <div className={styles.action} ref={duplicateRef}>
                            <DuplicateAction hash={hash} resumeId={resumeId} />
                        </div>
                    </div>
                    {showMenu && (
                        <div className={styles.more}>
                            <DesktopActionsMenu
                                hash={hash}
                                editUrl={editUrl}
                                resumeId={resumeId}
                                showEdit={showEditMenu}
                                showDuplicate={showDuplicateMenu}
                                showVisibility={showVisibilityMenu}
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    }
);
