import Conversion from 'bloko/blocks/conversion';

import translation from 'lux/components/translation';

const TrlKeys = {
    suitableOneShort: 'myresume.suitableVacanciesCount.shortText.one',
    suitableSomeShort: 'myresume.suitableVacanciesCount.shortText.some',
    suitableManyShort: 'myresume.suitableVacanciesCount.shortText.many',
    suitableZeroShort: 'resumeList.actionline.suitableVacancies.shortText',
};

const SuitableVacanciesText = translation<{ count: number }>(({ count, trls }) => (
    <Conversion
        value={count}
        one={trls[TrlKeys.suitableOneShort]}
        some={trls[TrlKeys.suitableSomeShort]}
        many={trls[TrlKeys.suitableManyShort]}
        zero={trls[TrlKeys.suitableZeroShort]}
        hasValue={count > 0}
    />
));

export default SuitableVacanciesText;
