import { useCallback } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import BlokoLink from 'bloko/blocks/link';

import { useNotification } from 'lux/components/Notifications/Provider';
import { maxResumeLimitNotification } from 'lux/components/Notifications/ResumeForm';
import translation from 'lux/components/translation';
import fetcher from 'lux/modules/fetcher';
import { MAX_RESUME_LIMIT_EXCEEDED } from 'lux/modules/resume';
import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';

const cloneResume = (params, addNotification) => (dispatch) =>
    fetcher.post('/applicant/resumes/clone', null, { params }).then(
        ({ data }) => {
            Analytics.sendEvent('applicant', 'resume-started', 'double');
            dispatch(push(data.url));
        },
        (error) => {
            if (error.response && error.response.data && error.response.data.error === MAX_RESUME_LIMIT_EXCEEDED) {
                addNotification(maxResumeLimitNotification, { uniqueType: true });
            } else {
                defaultRequestErrorHandler(error, addNotification);
            }
        }
    );

const ResumeDuplicate = ({ cloneResume, remaining, hash, trls, id }) => {
    const { addNotification } = useNotification();
    const handleClick = useCallback(() => {
        cloneResume({ resume: hash }, addNotification);

        resumeActionLinkButtonClick({ buttonName: 'resume_duplicate', resumeId: id });
    }, [addNotification, cloneResume, hash, id]);

    if (remaining === 0) {
        return null;
    }

    return (
        <BlokoLink Element="button" type="button" data-qa="resume-dublicate" onClick={handleClick}>
            {trls[ResumeDuplicate.trls.action]}
        </BlokoLink>
    );
};

ResumeDuplicate.trls = {
    action: 'resumeList.actionline.duplicate',
};

ResumeDuplicate.propTypes = {
    cloneResume: PropTypes.func,
    hash: PropTypes.string,
    xsrfToken: PropTypes.string,
    max: PropTypes.number,
    remaining: PropTypes.number,
    trls: PropTypes.object,
    id: PropTypes.string.isRequired,
};

export default connect(
    (state) => ({
        max: state.resumeLimits.max,
        remaining: state.resumeLimits.remaining,
    }),
    {
        cloneResume,
    }
)(translation(ResumeDuplicate));
