import { useEffect, useState } from 'react';

import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import { H2 } from 'bloko/blocks/header';
import Modal, { ModalContent, ModalFooter, ModalHeader } from 'bloko/blocks/modal';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { JobSearchStatus, JobSearchStatusForm } from 'lux/models/applicantUserStatuses';

import ChangeForm from 'lux/components/Applicant/JobSearchStatus/ChangeForm';

const CHANGE_FORM_NAME = JobSearchStatusForm.MyResumesXsModal;

const TrlKeys = {
    save: 'jobSearchStatus.save',
    close: 'jobSearchStatus.close',
    heading: 'jobSearchStatus.heading',
};

interface MobileModalProps {
    visible: boolean;
    onClose: () => void;
    currentStatus: JobSearchStatus;
}

const MobileModal: TranslatedComponent<MobileModalProps> = ({ visible, onClose, currentStatus, trls }) => {
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (visible) {
            setIsDisabled(true);
        }
    }, [visible]);

    return (
        <Modal useBottomSheet visible={visible} onClose={onClose}>
            <ModalHeader>
                <H2>{trls[TrlKeys.heading]}</H2>
            </ModalHeader>
            <ModalContent>
                <ChangeForm
                    currentStatus={currentStatus}
                    onSuccess={onClose}
                    formId={CHANGE_FORM_NAME}
                    setDisabled={setIsDisabled}
                />
            </ModalContent>
            <ModalFooter>
                <FormSpacer>
                    <Button type={ButtonType.Button} onClick={onClose}>
                        {trls[TrlKeys.close]}
                    </Button>
                </FormSpacer>
                <FormSpacer>
                    <Button
                        type={ButtonType.Submit}
                        kind={ButtonKind.Primary}
                        form={CHANGE_FORM_NAME}
                        disabled={isDisabled}
                    >
                        {trls[TrlKeys.save]}
                    </Button>
                </FormSpacer>
            </ModalFooter>
        </Modal>
    );
};

export default translation(MobileModal);
