import { useRef } from 'react';
import addDays from 'date-fns/addDays';

import Analytics from '@hh.ru/analytics-js';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import BlokoLink from 'bloko/blocks/link';
import Text from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import FormatMoney from 'lux/components/FormatMoney';
import translation from 'lux/components/translation';
import { useIsHeadHunterPlatform } from 'lux/hooks/usePlatform';
import {
    ApplicantService,
    APPLICANT_SERVICE_ORDER_URL,
    ResumeAuditData,
    ResumeAuditStatus,
} from 'lux/modules/applicantServices';
import { useSelector } from 'lux/modules/useSelector';

import styles from './resume-audit.less';

const TrlKeys = {
    title: 'resumes.recommendations.resume_audit.title',
    titleStatusFinished: 'resumes.recommendations.resume_audit.title.finished',
    descriptionStatusDirty: 'resumes.recommendations.resume_audit.description.1',
    descriptionStatusWaiting: 'resumes.recommendations.resume_audit.description.2',
    button: 'resumes.recommendations.resume_audit.pay_button',
    buttonReady: 'resumes.recommendations.resume_audit.ready_button',
    buttonText: 'resumes.recommendations.resume_audit.pay_button_recommendation',
    buttonTextShort: 'resumes.recommendations.resume_audit.pay_button_recommendation.short',
};

const MONTH_IN_MS = 1000 * 60 * 60 * 24 * 30;

export const useResumeAudit = (
    resumeHash: string
): { order?: ResumeAuditData['orders'][number]; price: ResumeAuditData['price'] } | null => {
    const audit = useSelector((state) => state.resumeAuditRecommendation);
    const order = audit?.orders.find(
        (order) => order.resume?.hash === resumeHash && order.status !== ResumeAuditStatus.Archived
    );
    const hasApplicableResumes =
        audit?.applicableResumes.includes(resumeHash) &&
        !audit?.orders.find(
            (order) => order.resume?.hash === resumeHash && order.activationTime > Date.now() - MONTH_IN_MS
        );

    const showBanner = hasApplicableResumes || !!order;

    if (!audit || !showBanner) {
        return null;
    }

    return { order, price: audit.price };
};

const ResumeAudit: TranslatedComponent<{
    resumeHash: string;
    buttonOnly: boolean;
    withoutTopSpacing: boolean;
}> = ({ resumeHash, buttonOnly, withoutTopSpacing, trls }) => {
    const userType = useSelector((state) => state.userType);
    const audit = useResumeAudit(resumeHash);
    const auditBlockRef = useRef<HTMLDivElement>(null);
    const isHeadHunter = useIsHeadHunterPlatform();

    const sendButtonAnalytics = () => {
        Analytics.sendEvent(userType, 'applicant_myresume_resume_audit', 'click_resume_audit');
    };

    if (!audit) {
        return null;
    }

    const order = audit.order;

    const renderDescription = () => {
        if (!order) {
            return <>{trls[TrlKeys.descriptionStatusDirty]}</>;
        }

        switch (order.status) {
            case ResumeAuditStatus.Waiting: {
                const dueDate = order.dueDate || addDays(new Date(), 2);
                return formatToReactComponent(trls[TrlKeys.descriptionStatusWaiting], {
                    '{0}': <span suppressHydrationWarning>{formatDate(dueDate, 'd MMMM')}</span>,
                });
            }
        }

        return null;
    };

    const renderButton = () => {
        const hasButton = !order?.status || order?.status !== ResumeAuditStatus.Waiting;
        if (!hasButton) {
            return null;
        }

        const buttonText: JSX.Element | string = formatToReactComponent(trls[TrlKeys.button], {
            '{0}': <FormatMoney currency={audit.price.currency}>{audit.price.amount}</FormatMoney>,
        });
        const buttonLink = `/applicant-services/payment/${ApplicantService.ResumeAudit}?resumeHash=${resumeHash}`;
        const buttonApperance = ButtonAppearance.Filled;

        if (order?.status === ResumeAuditStatus.Finished) {
            return (
                <BlokoLink
                    Element={Link}
                    to={`/applicant-services/${APPLICANT_SERVICE_ORDER_URL[ApplicantService.ResumeAudit]}/order/${
                        order.orderCode
                    }`}
                    disableVisited
                >
                    {trls[TrlKeys.buttonReady]}
                </BlokoLink>
            );
        }

        return (
            <Button
                Element={Link}
                kind={ButtonKind.Primary}
                appearance={buttonApperance}
                scale={ButtonScale.Small}
                to={buttonLink}
                target="_blank"
                onClick={() => {
                    if (!order) {
                        sendButtonAnalytics();
                    }
                }}
            >
                {buttonText}
            </Button>
        );
    };

    const renderBanner = () => {
        return (
            <>
                {!withoutTopSpacing && <VSpacing base={4} />}
                <div ref={auditBlockRef}>
                    <Text strong>
                        {order?.status === ResumeAuditStatus.Finished
                            ? trls[TrlKeys.titleStatusFinished]
                            : trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={1} />
                    <div className={styles.infoblockText}>
                        <Text>{renderDescription()}</Text>
                    </div>
                    {renderButton()}
                </div>
            </>
        );
    };

    const renderRecommendationButton = () => {
        const buttonText = formatToReactComponent(trls[TrlKeys.buttonTextShort], {
            '{0}': <FormatMoney currency={audit.price.currency}>{audit.price.amount}</FormatMoney>,
        });
        const buttonLink = `/applicant-services/payment/${ApplicantService.ResumeAudit}?resumeHash=${resumeHash}`;

        return (
            <div
                ref={auditBlockRef}
                className="applicant-resumes-recommendations-button applicant-resumes-recommendations-button_wrapped"
            >
                <Button
                    kind={ButtonKind.Primary}
                    scale={ButtonScale.Small}
                    appearance={ButtonAppearance.Outlined}
                    stretched
                    Element={Link}
                    target="_blank"
                    to={buttonLink}
                    onClick={sendButtonAnalytics}
                >
                    {buttonText}
                </Button>
            </div>
        );
    };

    if (buttonOnly) {
        return !order && isHeadHunter ? renderRecommendationButton() : null;
    }

    if (order) {
        return renderBanner();
    }

    return null;
};

export default translation(ResumeAudit);
