import { useRef } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { H3Section } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { format } from 'bloko/common/trl';

import LoopCounterParser, { LoopCounterStage } from 'Modules/LoopCounterParser';
import Strings from 'Utils/Strings';
import LoopCounter from 'lux/components/Applicant/LoopCounter';
import translation from 'lux/components/translation';

import Footer from 'lux/components/Applicant/VacancyResponseCounter/Footer';

const Counter = ({ modifyCssClass, responsesRequired, responsesCount, noAnimations, relatedVacanciesHash, trls }) => {
    const words = trls[Counter.trls.pluralizeCount].split(',');
    const { roundCounter, completeStepCounter, stage } = LoopCounterParser(responsesRequired, responsesCount);

    const titleNumber =
        stage === LoopCounterStage.Success && roundCounter > 1
            ? responsesCount
            : responsesRequired - completeStepCounter;

    const containerRef = useRef();

    const containerData = {
        ref: containerRef,
        modifyCssClass,
    };

    return (
        <div
            ref={containerRef}
            className={classnames('vacancy-response-counter', {
                [modifyCssClass]: !!modifyCssClass,
                'vacancy-response-counter_no-animations': noAnimations,
                'vacancy-response-counter_shown': noAnimations,
                'vacancy-response-counter_progress': stage === LoopCounterStage.Progress,
                'vacancy-response-counter_success': stage === LoopCounterStage.Success,
            })}
        >
            <div className={'vacancy-response-counter__container'}>
                <div className="vacancy-response-counter__responded">
                    <LoopCounter
                        completeStepCounter={completeStepCounter}
                        containerData={containerData}
                        noAnimations={noAnimations}
                        requiredStepCounter={responsesRequired}
                        roundCounter={roundCounter}
                        stage={stage}
                    />
                </div>
                <div className="vacancy-response-counter__content">
                    <VSpacing base={5} />
                    <H3Section>
                        <span>
                            {format(trls[Counter.trls[stage].title], {
                                '{0}': `${Strings.numConversion(titleNumber, words)}`,
                            })}
                        </span>
                    </H3Section>
                    <VSpacing base={2} />
                    <div className="vacancy-response-counter__description">{trls[Counter.trls[stage].description]}</div>
                    {relatedVacanciesHash && <Footer hash={relatedVacanciesHash} />}
                    <VSpacing base={5} />
                </div>
            </div>
        </div>
    );
};

Counter.trls = {
    pluralizeCount: 'vacancy.counter.responses.pluralizeCount',
    success: {
        title: 'vacancy.counter.responses.title.success',
        description: 'vacancy.counter.responses.description.success',
    },
    progress: {
        title: 'vacancy.counter.responses.title.progress',
        description: 'vacancy.counter.responses.description.progress',
    },
    relatedVacanciesHash: 'vacancy.counter.responses.relatedVacanciesHash',
};

Counter.propTypes = {
    modifyCssClass: PropTypes.string,
    responsesCount: PropTypes.number,
    responsesRequired: PropTypes.number,
    relatedVacanciesHash: PropTypes.string,
    noAnimations: PropTypes.bool,
    trls: PropTypes.object,
};

export default translation(Counter);
